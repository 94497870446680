var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"group_2 flex-row"},[_c('div',{staticClass:"group_3 flex-col"}),_c('img',{staticClass:"label_1",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/pshqjut0w3odxut27jzu3l32s9214ro7ifdd3b641-781a-443a-b6a4-6f15a1cf5577.png")}}),_c('div',{staticClass:"section_6 flex-col"},[_c('span',{staticClass:"text_1"},[_vm._v("登录 / 注册")]),_vm._m(0),_c('div',{staticClass:"section_1 flex-col"}),_vm._m(1),_c('div',{staticClass:"section_2 flex-row"},[_c('el-input'),_c('img',{staticClass:"image_1",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/ps0npzfbqfpcd9rec8oobbvg4125krxi60tc46d0e94-a6f9-4c0d-95ca-715e5f5474ed.png")}})],1),_c('div',{staticClass:"section_7 flex-row justify-between"},[_c('div',{staticClass:"text-wrapper_3 flex-col"},[_c('el-input')],1),_vm._m(2)]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('img',{staticClass:"thumbnail_1",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/pseipx0walxxbjur4mpvzlcbwtz9zboijra59a7386-ecac-4b25-8b91-156fc3e2aa43.png")}})]),_c('img',{staticClass:"thumbnail_2",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/psjt459viakhysogousbcje2iejtt5vktf01422e5-50cd-4aa1-8353-0aba6d5835ee.png")}}),_c('img',{staticClass:"label_3",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/psdl53zgxxr9kjnlbiwx03rk2uf9b0pmvyke459ec88-ce1a-4c25-a223-819aa099be39.png")}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-wrapper_6 flex-row justify-between"},[_c('span',{staticClass:"text_2"},[_vm._v("手机号")]),_c('span',{staticClass:"text_3"},[_vm._v("邮箱")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-wrapper_2 flex-col"},[_c('span',{staticClass:"text_4"},[_vm._v("19528021@outlook.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-wrapper_4 flex-col"},[_c('span',{staticClass:"text_7"},[_vm._v("名字")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-text_2 flex-row justify-between"},[_c('img',{staticClass:"label_2",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/ps0mvu9bojae5ikq2vqstkgqeuv09cr1h9pe9c2a621-3d31-4174-8dd0-9e06ecd9ca02.png")}}),_c('div',{staticClass:"text-group_1"},[_c('span',{staticClass:"text_8"},[_vm._v("继续操作即表示接受")]),_c('span',{staticClass:"text_9"},[_vm._v("用户协议")]),_c('span',{staticClass:"text_10"},[_vm._v(" 、")]),_c('span',{staticClass:"text_11"},[_vm._v("可接受使用政策")]),_c('span',{staticClass:"text_12"},[_vm._v("、")]),_c('span',{staticClass:"text_13"},[_vm._v("隐私政策")]),_c('span',{staticClass:"text_14"},[_vm._v("和")]),_c('span',{staticClass:"text_15"},[_vm._v("Cookie政策")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_8 flex-row justify-between"},[_c('div',{staticClass:"box_1 flex-col"}),_c('span',{staticClass:"text_16"},[_vm._v("愿意以电子邮件或短信的形式接收来自港专租的独家优惠和最新房源资讯")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-wrapper_5 flex-col"},[_c('span',{staticClass:"text_17"},[_vm._v("立即注册")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_9 flex-row justify-between"},[_c('img',{staticClass:"image_2",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/psp8kzf0122sxrizlv5o08klxbky2fq32c1fd62aa-13ac-40ef-8306-06036ddd9174.png")}}),_c('span',{staticClass:"text_18"},[_vm._v("其他方式")]),_c('img',{staticClass:"image_3",attrs:{"referrerpolicy":"no-referrer","src":require("./assets/img/pspd6k168zpjdql3v259w7sllasxqxtjnz5049fb52-76c8-41ca-bf7e-2f3149ffeb20.png")}})])
}]

export { render, staticRenderFns }