<template>
  <div>
    <div class="group_2 flex-row">
      <div class="group_3 flex-col"></div>
      <img class="label_1" referrerpolicy="no-referrer"
        src="./assets/img/pshqjut0w3odxut27jzu3l32s9214ro7ifdd3b641-781a-443a-b6a4-6f15a1cf5577.png" />
      <div class="section_6 flex-col">
        <span class="text_1">登录&nbsp;/&nbsp;注册</span>
        <div class="text-wrapper_6 flex-row justify-between">
          <span class="text_2">手机号</span>
          <span class="text_3">邮箱</span>
        </div>
        <div class="section_1 flex-col"></div>
        <div class="text-wrapper_2 flex-col"><span class="text_4">19528021&#64;outlook.com</span></div>
        <div class="section_2 flex-row">
          <el-input></el-input>
          <img class="image_1" referrerpolicy="no-referrer"
            src="./assets/img/ps0npzfbqfpcd9rec8oobbvg4125krxi60tc46d0e94-a6f9-4c0d-95ca-715e5f5474ed.png" />
        </div>
        <div class="section_7 flex-row justify-between">
          <div class="text-wrapper_3 flex-col"><el-input></el-input></div>
          <div class="text-wrapper_4 flex-col"><span class="text_7">名字</span></div>
        </div>
        <div class="image-text_2 flex-row justify-between">
          <img class="label_2" referrerpolicy="no-referrer"
            src="./assets/img/ps0mvu9bojae5ikq2vqstkgqeuv09cr1h9pe9c2a621-3d31-4174-8dd0-9e06ecd9ca02.png" />
          <div class="text-group_1">
            <span class="text_8">继续操作即表示接受</span>
            <span class="text_9">用户协议</span>
            <span class="text_10">&nbsp;、</span>
            <span class="text_11">可接受使用政策</span>
            <span class="text_12">、</span>
            <span class="text_13">隐私政策</span>
            <span class="text_14">和</span>
            <span class="text_15">Cookie政策</span>
          </div>
        </div>
        <div class="section_8 flex-row justify-between">
          <div class="box_1 flex-col"></div>
          <span class="text_16">愿意以电子邮件或短信的形式接收来自港专租的独家优惠和最新房源资讯</span>
        </div>
        <div class="text-wrapper_5 flex-col"><span class="text_17">立即注册</span></div>
        <div class="section_9 flex-row justify-between">
          <img class="image_2" referrerpolicy="no-referrer"
            src="./assets/img/psp8kzf0122sxrizlv5o08klxbky2fq32c1fd62aa-13ac-40ef-8306-06036ddd9174.png" />
          <span class="text_18">其他方式</span>
          <img class="image_3" referrerpolicy="no-referrer"
            src="./assets/img/pspd6k168zpjdql3v259w7sllasxqxtjnz5049fb52-76c8-41ca-bf7e-2f3149ffeb20.png" />
        </div>
        <img class="thumbnail_1" referrerpolicy="no-referrer"
          src="./assets/img/pseipx0walxxbjur4mpvzlcbwtz9zboijra59a7386-ecac-4b25-8b91-156fc3e2aa43.png" />
      </div>
      <img class="thumbnail_2" referrerpolicy="no-referrer"
        src="./assets/img/psjt459viakhysogousbcje2iejtt5vktf01422e5-50cd-4aa1-8353-0aba6d5835ee.png" />
      <img class="label_3" referrerpolicy="no-referrer"
        src="./assets/img/psdl53zgxxr9kjnlbiwx03rk2uf9b0pmvyke459ec88-ce1a-4c25-a223-819aa099be39.png" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />