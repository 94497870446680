<template>
    <div>
        <Top_head></Top_head>

        <div class="room_xq">
            <div class="g_cw g_mt">
                <div class="g_title">
                    <h5>{{ activity.title }}</h5>
                </div>
            </div>


            <div class="room_js">
                <div class=" g_cw">
                    <div class="one">
                        <div class="center g_mb">
                            <img class="ct_a" :src="activity.showPic" style="width: 100%;" />
                        </div>
                    </div>
                    <div class="js">{{ activity.remark }}</div>



                    <div class="wb_js g_mt">
                        <div class="bt">
                            <h4>活动详情</h4> 活动时间:{{ activity.beginDate }}--{{ activity.endDate }}
                        </div>

                        <div class="js">
                            <pre class="l1">  <div s class="show-html" v-html="activity.detail"></div></pre>
                        </div>
                        <div style="width: 100%;text-align: center;margin-bottom: 60px;margin-top: 60px;"> <el-button
                                type="primary" style="width: 80%;" round @click="handleNew">我要报名</el-button></div>

                    </div>
                </div>
            </div>
        </div>

        <Bottom></Bottom>
        <el-dialog title="活动报名登记" :visible.sync="dialogFormVisible" width="680px" :close-on-click-modal="false"
            :destroy-on-close="true">

            <el-form :model="form" size="mini" :rules="rules" ref="form" v-if="activity.formType == 'normal'">
                <el-form-item label="姓名" :label-width="formLabelWidth" prop="realName">
                    <el-input v-model="form.realName" autocomplete="off"></el-input>
                </el-form-item>


                <el-form-item label="手机" :label-width="formLabelWidth" prop="mobile">
                    <el-input v-model="form.mobile" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="微信号" :label-width="formLabelWidth" prop="wechat">
                    <el-input v-model="form.wechat" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="称谓" :label-width="formLabelWidth" prop="gender">
                    <el-radio-group v-model="form.gender" size="medium">
                        <el-radio-button label="1">男士</el-radio-button>
                        <el-radio-button label="0">女士</el-radio-button>
                        <el-radio-button label="2">其他</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="职业(学生等)" :label-width="formLabelWidth" prop="major">
                    <el-input v-model="form.major" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" v-if="activity.formType == 'normal'">
                <el-button @click="resetForm('form')">取 消</el-button>
                <el-button type="primary" @click="submitForm('form')">提交报名</el-button>
            </div>



            <!-- 表单2 -->
            <el-form :model="formNew" size="mini" :rules="rules2" ref="formNew" v-if="activity.formType == 'new'">
                <el-form-item label="姓名" :label-width="formLabelWidth" prop="realName">
                    <el-input v-model="formNew.realName" autocomplete="off"></el-input>
                </el-form-item>


                <el-form-item label="手机" :label-width="formLabelWidth" prop="mobile">
                    <el-input v-model="formNew.mobile" autocomplete="off"></el-input>
                </el-form-item>


                <el-form-item label="微信号" :label-width="formLabelWidth" prop="wechat">
                    <el-input v-model="formNew.wechat" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="Email" :label-width="formLabelWidth" prop="email">
                    <el-input v-model="formNew.email" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="年龄" :label-width="formLabelWidth" prop="age">
                    <el-input v-model="formNew.age" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="所在城市" :label-width="formLabelWidth" prop="city">
                    <el-input v-model="formNew.city" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="详细地址" :label-width="formLabelWidth" prop="address">
                    <el-input v-model="formNew.address" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="预计来港日期" :label-width="formLabelWidth" prop="checkInTime">

                    <el-date-picker v-model="formNew.checkInTime" type="date" placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="称谓" :label-width="formLabelWidth" prop="gender">
                    <el-radio-group v-model="formNew.gender" size="medium">
                        <el-radio-button label="1">男士</el-radio-button>
                        <el-radio-button label="0">女士</el-radio-button>
                        <el-radio-button label="2">其他</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="来港就读大学" :label-width="formLabelWidth" prop="university">
                    <el-input v-model="formNew.university" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="入学证明" :label-width="formLabelWidth" prop="offerLetter">
                    <template>
                        入学证明(Offer Letter) *只须看到名字及就读院校, 其他讯息可自行遮蔽
                        <single_pic @upFileName="showpicVal"></single_pic>
                    </template>
                </el-form-item>
                <el-form-item label="转发截图" :label-width="formLabelWidth" prop="sharePic">
                    <template>
                        转发分享截图*必須提供转发至100人以上的群/ 上载至朋友圈/ 小红书之截图
                        <single_pic @upFileName="showpicVal1"></single_pic>
                    </template>
                </el-form-item>
                <el-form-item label="留言" :label-width="formLabelWidth" prop="message">
                    <el-input v-model="formNew.message" placeholder="通过哪里发现我们小程序" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer" v-if="activity.formType == 'new'">
                <el-button @click="resetForm('formNew')">取 消</el-button>
                <el-button type="primary" @click="submitForm('formNew')">提交报名</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import * as api from "../../api/api"
import Top_head from '@/components/Top_head.vue';
import Bottom from "@/components/Bottom.vue";
import single_pic from "@/components/single_pic.vue"
export default {
    components: { Top_head, Bottom, single_pic },
    created: async function () {
        let id = this.$route.query.id
        this.set_id = id
        // console.log(this.set_id)
        this.get_data(id)



    },
    filters: {
        ellipsis(value) {
            if (!value) return '';
            if (value.length > 50) {
                return value.slice(0, 50) + '...'
            }
            return value
        }
    },
    data() {
        return {
            totalRecord: 0,
            currentpage: 1,
            pagesize: 9,

            activity: {},
            show_html: "",
            form: {
                "realName": "",
                "gender": 1,
                "mobile": "",
                "wechat": "",

                "major": "",
                "shareKey": "test",
                "activityId": this.set_id
            },
            formNew: {

                "age": "",
                "checkInTime": "",
                "city": "",
                "realName": "",
                "email": "",

                "gender": 1,

                "mobile": "",
                "wechat": "",
                "university": "",
                "address": "",
                "shareKey": "test",
                "activityId": this.set_id,
                "offerLetter": "",
                "sharePic": "",
                "message": ""
            },
            rules: {
                realName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],

                mobile: [
                    { required: true, message: '请输入联系手机', trigger: 'blur' },
                ],

                wechat: [
                    { required: true, message: '请输入联系微信号', trigger: 'blur' },
                ],

            },
            rules2: {
                realName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                checkInTime: [
                    { required: true, message: '请输预计来港日期', trigger: 'blur' },
                ],
                mobile: [
                    { required: true, message: '请输入联系手机', trigger: 'blur' },
                ],
                email: [
                    { required: true, message: '请输入联系Email', trigger: 'blur' },
                ],
                wechat: [
                    { required: true, message: '请输入联系微信号', trigger: 'blur' },
                ],
                age: [
                    { required: true, message: '请输入年龄', trigger: 'blur' },
                ],
                address: [
                    { required: true, message: '请输入您的详细地址(接收礼品必须)', trigger: 'blur' },
                ],
                city: [
                    { required: true, message: '请输入您所在的城市', trigger: 'blur' },
                ],
                offerLetter: [
                    { required: true, message: '请上传入学证明', trigger: 'blur' },
                ],
                sharePic: [
                    { required: true, message: '请输入转发截图', trigger: 'blur' },
                ],
                university: [
                    { required: true, message: '请输入您在香港就读的大学', trigger: 'blur' },
                ],
            },
            dialogFormVisible: false,
            formLabelWidth: '150px'

        };
    },

    methods: {
        click_menu(topage) {
            this.$router.push(topage);
        },
        click_detail(id) {
            this.$router.push('/eventDetail?id=' + id);
        },
        get_data(id) {
            var params = {
                id: id
            }
            api.getRequest('activitys/detail',
                params).then(resp => {
                    // console.log(resp)

                    this.activity = resp.data.detail
                    // console.log(this.activity)
                })
        },
        handleNew() {

            this.dialogFormVisible = true;


        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogFormVisible = false
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.save()
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        },
        showpicVal(data) {
            // console.log("uppic")
            this.formNew.offerLetter = data
        },
        showpicVal1(data) {
            // console.log("uppic2")
            this.formNew.sharePic = data
        },
        save() {
            if (this.activity.formType == 'normal') {
                let newdata = this.form;
                api.postRequest('/activityFormNormal/web', newdata).then(resp => {
                    if (resp.data.count == 1) {
                        this.$message.success("提交登记成功, 请保持手机畅通,我们讲尽快与您取得联系!");
                        this.resetForm('form')
                    } else {
                        this.$message.error(resp.message);
                    }
                })
            }
            if (this.activity.formType == 'new') {
                let newdata = this.formNew;
                // console.log(newdata)
                api.postRequest('/activityFormNnew/web', newdata).then(resp => {
                    if (resp.data.count == 1) {
                        this.$message.success("提交登记成功, 请保持手机畅通,我们讲尽快与您取得联系!");
                        this.resetForm('formNew')
                    } else {
                        this.$message.error(resp.message);
                    }
                })
            }

        },
        handleCancel() {
            this.dialogFormVisible = false;
            this.resetForm('form')
            this.resetForm('formNew')
        },


    }
}
</script>
<style scoped lang="css" src="./assets/index.css" />
<style scoped>
.show-html>>>img {
    max-width: 100%;
}
</style>