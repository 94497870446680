<template>
  <div class="page flex-col">
    <div class="group_21 flex-row">
      <div class="group_22 flex-col">
        <div class="block_2 flex-col">
          <div class="group_2 flex-row">
            <div class="group_3 flex-col"></div>
            <div class="block_22 flex-col">
              <span class="text_1">YHill学生公寓周末茶会、欢迎邻里参与</span>
              <div class="image-text_19 flex-row justify-between">
                <img
                  class="thumbnail_1"
                  referrerpolicy="no-referrer"
                  src="./assets/img/pscq0vq3oam7pod6mg4dnucja8m8r2r58o59f4bc31-e79c-4421-aa13-191aef6d9285.png"
                />
                <span class="text-group_1">2023/3/28&nbsp;-&nbsp;2023/4/5</span>
              </div>
              <div class="image-text_20 flex-row justify-between">
                <img
                  class="thumbnail_2"
                  referrerpolicy="no-referrer"
                  src="./assets/img/ps4qfi8c0dnoc2jhad7882mniclxhpeglk000ef378-5c12-4177-af68-9ec45c4b95b9.png"
                />
                <span class="text-group_2">YHill学生公寓大堂</span>
              </div>
              <span class="text_2">“得闲来饮茶”！饮茶是广东人的日常，更是广东人舒适、惬意的生活写照。</span>
              <div class="text-wrapper_1">
                <span class="text_3">已报名人数</span>
                <span class="text_4">42人</span>
              </div>
            </div>
            <div class="block_23 flex-col justify-between">
              <div class="text-wrapper_2 flex-col"><span class="text_5">报名中</span></div>
              <div class="text-wrapper_3 flex-col"><span class="text_6">进入活动</span></div>
            </div>
          </div>
          <div class="group_6 flex-row">
            <div class="block_3 flex-col"></div>
            <div class="group_23 flex-col">
              <span class="text_7">YHill学生公寓手工皮具制作、做生活的匠人</span>
              <div class="image-text_21 flex-row justify-between">
                <img
                  class="thumbnail_3"
                  referrerpolicy="no-referrer"
                  src="./assets/img/psk4vwj9g7wddtw1mp2tn5ok8p66d8qmk1517aa6f65-b27f-4703-b0c7-bfba0003539b.png"
                />
                <span class="text-group_3">2023/4/6&nbsp;-&nbsp;2023/4/8</span>
              </div>
              <div class="image-text_22 flex-row justify-between">
                <img
                  class="thumbnail_4"
                  referrerpolicy="no-referrer"
                  src="./assets/img/psq5x8wqai4lb17omp6i2j7hsqjzxx6vilkdc7a5a71-ac61-44e6-b72d-cae9a8f1296e.png"
                />
                <span class="text-group_4">YHill学生公寓大堂</span>
              </div>
              <span class="text_8">最紧要自己钱入自己袋，老匠人手把手教你做钱包</span>
              <div class="text-wrapper_4">
                <span class="text_9">已报名人数</span>
                <span class="text_10">12人</span>
              </div>
            </div>
            <div class="group_24 flex-col justify-between">
              <div class="text-wrapper_5 flex-col"><span class="text_11">报名中</span></div>
              <div class="text-wrapper_6 flex-col"><span class="text_12">进入活动</span></div>
            </div>
          </div>
        </div>
        <div class="block_6 flex-row">
          <div class="box_1 flex-col"></div>
          <div class="section_2 flex-col">
            <span class="text_13">春游拓展活动，长洲岛公益骑行</span>
            <div class="image-text_23 flex-row justify-between">
              <img
                class="thumbnail_5"
                referrerpolicy="no-referrer"
                src="./assets/img/psn305s7lr976ft02vzxxfuu1n1pcknc2tedfd0519-2051-4889-9091-e646f3cabfc0.png"
              />
              <span class="text-group_5">2023/4/6&nbsp;-&nbsp;2023/4/8</span>
            </div>
            <div class="image-text_24 flex-row justify-between">
              <img
                class="thumbnail_6"
                referrerpolicy="no-referrer"
                src="./assets/img/pswyn4q2elnz8zt1nlnl8epi8e1724khuhla9d0b53b-6d50-4fb5-8ba8-053ee333dacb.png"
              />
              <span class="text-group_6">尖沙咀地铁站</span>
            </div>
            <span class="text_14">与公路骑士一路前行，为公益出一份力</span>
            <div class="text-wrapper_7">
              <span class="text_15">已报名人数</span>
              <span class="text_16">21人</span>
            </div>
          </div>
          <div class="section_3 flex-col justify-between">
            <div class="text-wrapper_8 flex-col"><span class="text_17">报名中</span></div>
            <div class="text-wrapper_9 flex-col"><span class="text_18">进入活动</span></div>
          </div>
        </div>
        <div class="block_7 flex-col">
          <div class="block_8 flex-col">
            <div class="text-group_12 flex-col justify-between">
              <span class="text_19">扫描关注港专租小程序</span>
              <span class="text_20">微信小程序第一手租房资源尽在掌中，快快来关注，优惠活动享不停。</span>
            </div>
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/psxrha11uqrckcikukae4zsxg0i3cmso90860ba4-0b29-4141-a14e-f143616975d3.png"
            />
          </div>
        </div>
      </div>
      <div class="block_9 flex-col">
        <div class="group_25 flex-row">
          <div class="group_8 flex-row">
            <div class="image-text_25 flex-row justify-between">
              <img
                class="label_1"
                referrerpolicy="no-referrer"
                src="./assets/img/psxl3dg5d01efixogogth762l1rq3j2moo1772a403-66c9-4f2f-8efd-edf2516a7e62.png"
              />
              <span class="text-group_8">简体中文</span>
            </div>
            <img
              class="thumbnail_7"
              referrerpolicy="no-referrer"
              src="./assets/img/psujcwr4q8jp07wgsmwrwna4eps9jweq5a7fc6f7455-9185-4e47-9d61-2f39f592ce28.png"
            />
          </div>
          <span class="text_21">港专租</span>
          <span class="text_22">合作咨询</span>
          <span class="text_23">其他</span>
          <span class="text_24">订阅邮件咨询</span>
        </div>
        <div class="group_26 flex-row">
          <div class="text-wrapper_27 flex-col justify-between">
            <span class="text_25">关于我们</span>
            <span class="text_26">官方微信</span>
          </div>
          <div class="text-wrapper_28 flex-col justify-between">
            <span class="text_27">成为合作伙伴</span>
            <span class="text_28">房源合作</span>
          </div>
          <div class="text-wrapper_29 flex-col justify-between">
            <span class="text_29">常见问题</span>
            <span class="text_30">加入我们</span>
          </div>
          <div class="group_10 flex-row">
            <span class="text_31">输入邮件地址</span>
            <div class="box_4 flex-col">
              <div class="image-wrapper_1 flex-col">
                <img
                  class="image_2"
                  referrerpolicy="no-referrer"
                  src="./assets/img/psriutqbha88gxr1d2mbyalb7ywt8yvux9ad5f781e-3e12-4245-8694-168e6c4cd6d1.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="text-wrapper_30 flex-row"><span class="text_32">虚位以待</span></div>
        <div class="text-wrapper_31 flex-row"><span class="text_33">Copy&nbsp;Right©中国香港港专租有限公司</span></div>
      </div>
    </div>
    <div class="group_11 flex-col">
      <div class="box_5 flex-col">
        <div class="group_12 flex-col"></div>
        <div class="group_13 flex-col">
          <div class="box_7 flex-row">
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/pswm72kzvyg4bba3274eojerm21ptaeo8x85413a243-dfa3-4599-bbd3-98bde7c42b08.png"
            />
            <span class="text_34">首页</span>
            <span class="text_35">房东房源</span>
            <span class="text_36">公寓房源</span>
            <span class="text_37">酒店房源</span>
            <span class="text_38">服务</span>
            <span class="text_39">活动</span>
            <span class="text_40">最新消息</span>
            <span class="text_41">登录</span>
            <img
              class="label_2"
              referrerpolicy="no-referrer"
              src="./assets/img/ps420mqdbg1yw0wgnqvp24dvl3v2rzi7jrka94b3e9c-536e-4c65-82e4-edc83aba112c.png"
            />
            <img
              class="thumbnail_8"
              referrerpolicy="no-referrer"
              src="./assets/img/pscz3nqjv83qthy5x68trej5ipgaxfmeyzoecf2e8ff-f6c9-4d79-adc8-f9ef0f958c30.png"
            />
            <div class="box_6 flex-col"></div>
          </div>
          <div class="text-wrapper_32 flex-row"><span class="text_42">派对活动齐参加</span></div>
          <div class="text-wrapper_33 flex-row"><span class="text_43">可靠、品质、一站式来港租住服务平台</span></div>
          <div class="block_11 flex-row justify-between">
            <div class="block_12 flex-col"></div>
            <div class="group_27 flex-col">
              <div class="block_24 flex-row justify-between">
                <span class="text_44">踏青2023长洲岛环保活动</span>
                <div class="text-wrapper_17 flex-col"><span class="text_45">报名中</span></div>
              </div>
              <div class="image-text_26 flex-row justify-between">
                <img
                  class="thumbnail_9"
                  referrerpolicy="no-referrer"
                  src="./assets/img/ps6zlmvp5f6xstdk2mkwdmnk6fesdg6ftusb94b1b31-4f5b-4adb-9721-9c2f64cf2cdc.png"
                />
                <span class="text-group_9">2023/3/28&nbsp;-&nbsp;2023/4/5</span>
              </div>
              <div class="image-text_27 flex-row justify-between">
                <img
                  class="thumbnail_10"
                  referrerpolicy="no-referrer"
                  src="./assets/img/psbnrlmarnpigq1us1795eu6jlolbb1zunc5968945-c829-4610-abe9-7f14747c5cc6.png"
                />
                <span class="text-group_10">旺角朗豪坊中庭</span>
              </div>
              <span class="text_46">
                每个人的心中植入公益环保的种子&nbsp;让它在心里生根发芽&nbsp;保游,守护旅业美好出行&nbsp;环保,守护人类美好星球&nbsp;保险是爱与责任,是守护美好。
              </span>
              <div class="block_25 flex-row justify-between">
                <div class="text-wrapper_18">
                  <span class="text_47">已报名人数</span>
                  <span class="text_48">24人</span>
                </div>
                <div class="text-wrapper_19 flex-col"><span class="text_49">进入活动</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />