<template>
  <div>
    <Top_head></Top_head>

    <div class="box-center">
      <div class="text-wrapper_1">
        <span class="text_9">首页&nbsp;/&nbsp;全部房源&nbsp;/</span>
        <span class="text_10">{{ house.title }}</span>
      </div>

    </div>
    <div class="box-center">
      <div class="page flex-col justify-between">
        <div class="box_1 flex-col">


          <div class="section_43 flex-row justify-between">
            <div class="group_2 flex-col">
              <div class="block" style="width: 890px; height: 540px"> <el-carousel trigger="click" width="890px"
                  height="540px">
                  <el-carousel-item v-for="item in showpicEdit" :key="item.index">
                    <el-image style="width: 890px; height: 540px" :src="item" fit="cover" :preview-src-list="showpicEdit">
                    </el-image>
                  </el-carousel-item>
                </el-carousel></div>



            </div>
            <div class="group_81 flex-col">
              <div class="section_3 flex-col">
                <div class="group_82 flex-row justify-between">
                  <div class="image-wrapper_1 flex-col">
                    <img class="thumbnail_4" referrerpolicy="no-referrer"
                      src="./assets/img/psb630b44e4arckqt5cfcfx7cc4fxeslkuh0c650632-dc09-4b30-898c-703b1de6c282.png" />
                  </div>
                  <span class="text_15">房源保证</span>
                </div>
                <span class="paragraph_1">
                  该房源已通过平台验证，可享有全额退款及免费取消，请放心
                  <br />
                  入住，如有疑问可以联系在线客服
                </span>
              </div>
              <span class="paragraph_2">
                {{ house.title }}
              </span>

              <span class="text_16">租赁价</span>
              <div class="text-wrapper_3">
                <span class="text_19">HK$</span>
                <span class="text_17">{{ house.price }}</span>
                <span class="text_18">起</span>

              </div>
              <div style="width: 400px;margin-left: 20px;margin-top: 10px;">
                <div class="tags" v-for="item in tags"><span style="color: #2AADF9;"></span>{{ item }}</div>

              </div>

              <div class="text-wrapper_11 flex-col" @click="handleNew"><span class="text_27">选择房间</span></div>
            </div>
          </div>
          <div class="section_45 flex-row justify-between">
            <div class="box_3 flex-col justify-end">
              <div class="text-wrapper_65 flex-row justify-between">
                <span class="text_32">房源详情</span>
                <span class="text_33">房间类型</span>
                <span class="text_35">公寓简介</span>
                <span class="text_34">地理位置及交通指南</span>

              </div>
              <div class="box_136 flex-row">
                <div class="group_7 flex-col"></div>
              </div>
            </div>
            <div class="section_9 flex-col">
              <div class="image-text_83 flex-row justify-between">
                <div class="image-wrapper_5 flex-col">
                  <img class="thumbnail_12" referrerpolicy="no-referrer"
                    src="./assets/img/psj73a16hlhqi26jcad2qrcqqwcjzsxi1xsed135b2e-53ca-478e-b576-b7fc167075d6.png" />
                </div>
                <span class="text-group_3">在线客服</span>
              </div>

              <span class="text_38">需要了解更多详细信息吗？</span>
              <span class="text_39">马上联系&nbsp;&gt;</span>
              <img class="image_4" referrerpolicy="no-referrer" src="../../assets/img/qrcode.png" />
            </div>
          </div>
          <div class="block_7 flex-col">



            <img class="image_24" referrerpolicy="no-referrer"
              src="./assets/img/ps8owfzjqnpo9ogutn7j3fpdfyaeekufub9f85ecf-eca1-4c1f-9a58-c48c6e062d2a.png" />
            <div class="image-text_93 flex-row justify-between">
              <img class="label_9" referrerpolicy="no-referrer"
                src="./assets/img/psfbu2iikt5eafc6nk7kk7if5trxs4dh5u7af5aba7-0d9b-47b7-86e2-8a3ff4800c77.png" />
              <span class="text-group_20">房间详情</span>
            </div>
            <div v-for="item in rooms" :key="item.id" style="display: flex;flex-direction: column;">

              <div class="block_26 flex-row">
                <span class="text_102">{{ item.id }}</span>
                <img class="image_16" referrerpolicy="no-referrer"
                  src="./assets/img/psi5pxltkvthok0zoopt9raclgoykwgew8a038e7c4-b79c-4b09-be42-0bf478da562d.png" />
                <span class="paragraph_3">
                  -&nbsp;{{ item.title }}
                  <br />
                  {{ item.intro }}

                </span>
                <el-image :src="item.image" class="group_33 flex-col" @click="get_room_detail(item.id)" fit="cover"
                  :preview-src-list="bed_pics">
                </el-image>

              </div>
              <div style="width: 100%;height: 100px;margin-top: 30px;margin-bottom: 30px;">
                <div v-for="obj in item.beds.data">

                  <div class="my-bed-list">
                    <div style="width: 260px;float: left;margin-top: 10px;">{{ obj.title }}--{{ obj.intro
                    }}</div>
                    <div style="margin-left: 100px;font-size: 18px;float: left;margin-top: 10px;">HK$ <span
                        style="color: #FF2020 ;">{{
                          obj.price
                        }} /月</span></div>
                    <div><el-button type="primary" style="margin-left: 40px;" @click="handleNew" round>预登记</el-button>
                    </div>
                  </div>
                  <div class="box-line"></div>
                </div>

              </div>
            </div>

            <div class="image-text_92 flex-row justify-between">
              <img class="label_8" referrerpolicy="no-referrer"
                src="./assets/img/ps56txh5wxj6oj34pz3fsdyax0d0kgzluhf1bb5eaf-5a7f-46ab-8e70-ce2f416774c0.png" />
              <span class="text-group_19">公寓简介</span>
            </div>
            <span class="text_100">
              {{ house.intro }}
            </span>
            <div s class="show-html" v-html="show_html"></div>

            <img class="image_25" referrerpolicy="no-referrer"
              src="./assets/img/psrd4qzft4vvz06h7xczaisx88vuo6djs3ea26b06-6760-4405-bac4-12be03863f79.png" />
            <div class="image-text_94 flex-row justify-between">
              <img class="label_10" referrerpolicy="no-referrer"
                src="./assets/img/ps4vbwvubhjxv0ms03t5yn7izmlk6bzj2gcb34d867-46aa-41b4-828c-8c75e6ed3cc1.png" />
              <span class="text-group_21">公寓设施</span>
            </div>
            <div class="block_29 flex-row">
              <div style="width: 660px;">
                <div v-for="item in facility"
                  style="height: 150px;width: 100px;float: left;text-align: center;display: flex;flex-direction: column;">
                  <el-image style="width: 72px; height: 72px;margin-left: 13px;margin-bottom: 15px;" :src="item.icon"
                    fit="cover">
                  </el-image>{{ item.name }}
                </div>
              </div>

            </div>



            <div class="image-text_95 flex-row justify-between">
              <img class="label_22" referrerpolicy="no-referrer"
                src="./assets/img/psiq2jicgx3qn5or2hz4heulark7xr2ar1746e7391d-c64e-43eb-aa16-7e89926b1ba1.png" />
              <span class="text-group_22">共享空间</span>
            </div>
            <div class="block_31 flex-row">
              <span class="text_123">Share</span>
              <img class="image_22" referrerpolicy="no-referrer"
                src="./assets/img/psht9cjn3gy5d4f6hzzcjd2t5v2h7n7cm1id476b363-be0b-416c-b27e-7a3985d49447.png" />
              <span class="paragraph_6">
                {{ house.shareRoom }}
              </span>

            </div>
            <div class="image-text_96 flex-row justify-between">
              <img class="label_23" referrerpolicy="no-referrer"
                src="./assets/img/ps4o7wmpo6qctv0b25i8mfgfmajrwi0zp22d50ba2-3a1d-479e-b556-f831ce7a87f7.png" />
              <span class="text-group_23">共享设施</span>
            </div>
            <div class="block_32 flex-row">
              <span class="text_124">Joy</span>
              <img class="image_23" referrerpolicy="no-referrer"
                src="./assets/img/psaxk3d1vtq99uqkov03a26l6tb7p4hc78h8c57e92b-a318-4b2b-bc25-76e9e6ea8435.png" />
              <span class="paragraph_7">
                {{ house.shareFacilities }}
              </span>

            </div>

          </div>
          <div class="block_3 flex-col">
            <div class="box_139 flex-row justify-between">
              <div class="image-text_85 flex-row justify-between">
                <img class="label_5" referrerpolicy="no-referrer"
                  src="./assets/img/psbwjmmq4u24c43ylkay4c091f2f3fwnrgj3e10d144-2487-47aa-9353-40388ad4b714.png" />
                <span class="text-group_5">地理位置</span>
              </div>
              <div class="image-text_86 flex-row justify-between">
                <img class="thumbnail_15" referrerpolicy="no-referrer"
                  src="./assets/img/ps6wfm9zg436hhi8rdjcvehhx965d2xce8bd1f9485-4bb7-4686-9f21-9a19200688e6.png" />
                <span class="text-group_6">{{ house.location
                }}</span>
              </div>
            </div>

            <el-image :src="house.showMap" class="box_10 flex-col" fit="cover"> </el-image>

            <div class="image-text_87 flex-row justify-between">
              <img class="label_6" referrerpolicy="no-referrer"
                src="./assets/img/ps3qm1qqbz60qapovhzh4kehfvgb25ngg8d9a07a829-ba6f-4649-90f7-a6b8048ecf2f.png" />
              <span class="text-group_10">交通指南</span>
            </div>

            <div class="box_146 flex-row">
              <div class="group_86 flex-col justify-between">
                <span class="text_79">Go</span>
                <img class="image_11" referrerpolicy="no-referrer"
                  src="./assets/img/ps51w60ln721cfukh64dyptnxqyod95c1xe071f2683-2b10-4ba0-8027-2b6cbd34e964.png" />
              </div>
              <img class="image_12" referrerpolicy="no-referrer"
                src="./assets/img/ps86aoc4fn49heqsogdeeu63tv7cf5h3d2455a440e-93e1-48b7-94db-216d228d567e.png" />

            </div>
            <div class="box_147 flex-row justify-between">
              <div class="block_4 flex-col">
                <div class="group_19 flex-col"></div>
              </div>
              <div class="text_80">-&nbsp;{{ house.trafficIntro }}</div>
            </div>

          </div>

        </div>

      </div>
    </div>
    <Bottom></Bottom>
    <el-dialog title="" :visible.sync="dialogFormVisible" width="680px" :close-on-click-modal="false"
      :destroy-on-close="true">
      <div class="show-title">房源预登记</div>
      <div class="sub-text ">应监管要求，请先进行信息确认. 稍后我们会有专人与您联系,请保持手机畅通. 感谢您的支持</div>
      <el-form :model="form" size="mini" style="margin-top: 50px;" :rules="rules" ref="form">
        <!-- <el-form-item label="姓名" :label-width="formLabelWidth" prop="client">
          <el-input v-model="form.client" autocomplete="off"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="预计入住时间" :label-width="formLabelWidth" prop="checkInTime">
          <el-date-picker v-model="form.checkInTime" type="datetime" align="center" value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item> -->

        <el-form-item label="手机" :label-width="formLabelWidth" prop="mobile">
          <el-input v-model="form.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="email" :label-width="formLabelWidth" prop="email">
          <el-input v-model="form.email" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="微信号" :label-width="formLabelWidth" prop="wechat">
          <el-input v-model="form.wechat" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="年龄" :label-width="formLabelWidth" prop="age">
          <el-input v-model="form.age" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="称谓" :label-width="formLabelWidth" prop="gender">
          <el-radio-group v-model="form.gender" size="medium">
            <el-radio-button label="1" value="1">男士</el-radio-button>
            <el-radio-button label="0" value="0">女士</el-radio-button>
            <el-radio-button label="2" value="2">其他</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="预算" :label-width="formLabelWidth" prop="budget">
          <el-input v-model="form.budget" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所在城市" :label-width="formLabelWidth" prop="city">
          <el-input v-model="form.city" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="特别要求" :label-width="formLabelWidth" prop="addition">
          <el-input v-model="form.addition" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="房间类型" :label-width="formLabelWidth" prop="bookRoomType">
          <el-select v-model="form.bookRoomType" placeholder="選擇房型">
            <el-option label="单人床" value="单人床"></el-option>
            <el-option label="双人床" value="双人床"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="证件号" :label-width="formLabelWidth" prop="idCard">
          <el-input v-model="form.idCard" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="来港就读大学" :label-width="formLabelWidth" prop="university">
          <el-input v-model="form.university" autocomplete="off"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('form')">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')" data-agl-cvt="3">确 定 提 交</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import * as api from "../../api/api"
import Top_head from '@/components/Top_head.vue';
import Bottom from "@/components/Bottom.vue";
export default {
  components: { Top_head, Bottom },
  created: async function () {

    let id = this.$route.query.id
    this.set_id = id
    this.get_data(id)
    this.get_html(id)

  },
  data() {
    return {
      house: [],
      showpicEdit: [],
      tags: [],
      show_html: "",
      rooms: [],
      facility: [],
      bed_pics: [],
      set_id: '',
      form: {
        "id": "",
        "addition": "",
        "age": 1,
        "bookRoomType": "单人床",
        "bookStatus": 0,
        "budget": "",
        "checkInTime": "",
        "city": "",
        "client": "",
        "email": "",
        "fromHouse": "",
        "gender": 1,
        "idCard": "",
        "mobile": "",
        "userId": 0,
        "wechat": ""
      },
      rules: {
        // client: [
        //   { required: true, message: '请输入姓名', trigger: 'blur' },
        // ],
        // checkInTime: [
        //   { required: true, message: '请输入计入住时间', trigger: 'blur' },
        // ],
        mobile: [
          { required: true, message: '请输入联系手机', trigger: 'blur' },
        ],
        // email: [
        //   { required: true, message: '请输入联系Email', trigger: 'blur' },
        // ],
        wechat: [
          { required: true, message: '请输入联系微信号', trigger: 'blur' },
        ],
        // age: [
        //   { required: true, message: '请输入年龄', trigger: 'blur' },
        // ],
        // budget: [
        //   { required: true, message: '请输入您的预算', trigger: 'blur' },
        // ],
        // city: [
        //   { required: true, message: '请输入您所在的城市', trigger: 'blur' },
        // ],
        bookRoomType: [
          { required: true, message: '请输入您希望的房型', trigger: 'blur' },
        ],
        // idCard: [
        //   { required: true, message: '请输入您的证件号', trigger: 'blur' },
        // ],
        university: [
          { required: true, message: '请输入您在香港就读的大学', trigger: 'blur' },
        ],
      },
      dialogFormVisible: false,
      formLabelWidth: '150px'

    };
  },
  methods: {
    get_data(id) {
      var params = {
        id: id
      }
      api.getRequest('/house/detail',
        params).then(resp => {
          // console.log(resp.data)
          this.house = resp.data.detail
          this.showpicEdit = api.return_pics_json_just_item(resp.data.image.data)
          this.tags = resp.data.tagNames
          this.rooms = resp.data.rooms.room
          this.facility = resp.data.facility.facility

          // console.log(this.facility)


        })
    },
    get_html(id) {
      var params = {
        id: id
      }
      api.getRequest('/house/detail/html',
        params).then(resp => {

          this.show_html = resp.data.html

        })
    },
    get_room_detail(id) {
      var params = {
        id: id
      }
      api.getRequest('/room/detail',
        params).then(resp => {
          this.bed_pics = api.return_pics_json_just_item(resp.data.image.data)
          // console.log(this.bed_pics)
        })
    },
    handleNew() {

      this.dialogFormVisible = true;
      this.form = {};

    },
    save() {
      let newdata = this.form;

      newdata.bookStatus = 0
      newdata.fromHouse = this.set_id
      newdata.fromSys = "website"
      newdata.crtBy = this.crtBy
      api.postRequest('/booking', newdata).then(resp => {
        if (resp.data == 1) {
          this.$message.success("提交登记成功, 请保持手机畅通,我们讲尽快与您取得联系!");
          this.resetForm('form')
        } else {
          this.$message.error(resp.message);
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false
    },
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" ></style>
<style scoped>
.show-html>>>img {
  max-width: 100%;
}
</style>