
<template>
    <div>
        <Top_head></Top_head>


        <div class="block_1">
            <div class="box-center">
                <div style="width: 1220px;margin-top: 20px;">
                    <div class="section_1 flex-row justify-end">
                        <span class="text_11"><input type="text" style="width: 300px;font-size: 18px;border-style:none;"
                                placeholder="欢迎来到香港，您想租住哪里呢？" v-model="search"></span>
                        <div class="box_3 flex-col"></div>

                        <div class="text-wrapper_2 flex-col" @click="get_data"><span class="text_13">立即查询</span></div>

                    </div>
                    <div class="section_2 flex-col">
                        <div class="block_15 flex-row">
                            <span class="text_14">找房范围</span>
                            <span :class="{
                                text_18: select_section != '', text_18_a: select_section == ''
                            }" @click="click_section('')">不限</span>
                            <div style="width: 1050px;">
                                <span :class="{ text_19: select_section != item, text_19_a: select_section == item }"
                                    v-for="item in section" @click="click_section(item)" :key="item">{{
                                        item
                                    }}</span>
                            </div>
                        </div>
                        <div class="image-wrapper_17 flex-row">
                            <img class="image_2" referrerpolicy="no-referrer"
                                src="./assets/img/psky3mdz09wqnjenjnkcyxm98n1t4k744gb029df188-ae95-4a87-95d5-633084242fe0.png" />
                        </div>
                        <div class="block_15 flex-row">
                            <span class="text_14">房源标签</span>
                            <span :class="{
                                text_18: select_tag != '', text_18_a: select_tag == ''
                            }" @click="click_tag('')">不限</span>
                            <div style="width: 1050px;">
                                <span :class="{ text_19: select_tag != item.id, text_19_a: select_tag == item.id }"
                                    @click="click_tag(item.id)" v-for="item in tags">{{ item.tagName
                                    }}</span>
                            </div>
                        </div>
                        <div class="image-wrapper_17 flex-row">
                            <img class="image_2" referrerpolicy="no-referrer"
                                src="./assets/img/psky3mdz09wqnjenjnkcyxm98n1t4k744gb029df188-ae95-4a87-95d5-633084242fe0.png" />
                        </div>

                        <div class="block_15 flex-row">
                            <span class="text_14">出租方式</span>
                            <span :class="{
                                text_18: select_showType != '', text_18_a: select_showType == ''
                            }" @click="click_type('')">不限</span>
                            <div style="width: 1050px;">
                                <span
                                    :class="{ text_19: select_showType != item.value, text_19_a: select_showType == item.value }"
                                    @click="click_type(item)" v-for="item in rentType">{{ item.text
                                    }}</span>
                            </div>
                        </div>
                        <div class="image-wrapper_17 flex-row">
                            <img class="image_2" referrerpolicy="no-referrer"
                                src="./assets/img/psky3mdz09wqnjenjnkcyxm98n1t4k744gb029df188-ae95-4a87-95d5-633084242fe0.png" />
                        </div>

                        <div class="block_15 flex-row">
                            <span class="text_14">出租租金</span>
                            <span :class="{
                                text_18: select_price != '', text_18_a: select_price == ''
                            }" @click="click_price('')">不限</span>
                            <div style="width: 1050px;">
                                <span
                                    :class="{ text_19: select_price != item.value, text_19_a: select_price == item.value }"
                                    @click="click_price(item.value)" v-for="item in range">{{ item.text
                                    }}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div class="room">


                <div class="rm_xq">
                    <div class="g_cw g_mt">
                        <div class="right">
                            <ul class="list">

                                <li v-for="obj in houses" :key="obj.index" @click="click_detail(obj.id)">

                                    <div class="block_7 flex-col">
                                        <!-- <img class="rm_ct" :src="obj.image" /> -->
                                        <el-image style="border-radius: 12px 12px 0px 0px;width: 100%; height: 400px;"
                                            :src="obj.image" fit="cover">
                                        </el-image>
                                        <p class="l1">{{ obj.title }} <img class="image_21" referrerpolicy="no-referrer"
                                                src="./assets/img/pstid5wwg4wtwbs3i26a7lscsph67kthzc085716d1-3f95-48b6-9ff0-42c4227ce891.png" />
                                            <span class="text-group_11">{{ obj.section }}</span>
                                        </p>
                                        <div style="font-size: 12px;margin-left: 10px;color: cornflowerblue;">{{ obj.intro |
                                            ellipsis
                                        }}</div>
                                        <div style="margin-left: 10px;margin-bottom: 15px;margin-top: 10px;"><el-tag
                                                v-for="tags in obj.tagNames" style="margin-right: 15px;">{{ tags
                                                }}</el-tag></div>

                                        <span class="text_108">HK$ {{ obj.price }}/月</span>

                                    </div>


                                </li>



                            </ul>
                        </div>




                        <div class="pages">
                            <el-pagination @current-change="paging" :current-page="currentpage" :page-size="9" background
                                layout="total, prev, pager, next" :total="totalRecord">
                            </el-pagination>
                        </div>

                    </div>
                </div>


            </div>
        </div>
        <Bottom></Bottom>

    </div>
</template>
<script>
import * as api from "../../api/api"
import Top_head from '@/components/Top_head.vue';
import Bottom from "@/components/Bottom.vue";
export default {
    components: { Top_head, Bottom },
    created: async function () {
        if (this.$route.query.keyword) {
            this.search = this.$route.query.keyword
        }
        if (this.$route.query.rentType) {
            this.select_showType = this.$route.query.rentType
        }


        this.get_data()

    },
    filters: {
        ellipsis(value) {
            if (!value) return '';
            if (value.length > 50) {
                return value.slice(0, 50) + '...'
            }
            return value
        }
    },
    data() {
        return {
            search: "",
            totalRecord: 0,
            currentpage: 1,
            pagesize: 9,
            section: ["中西区", "东区", "南区", "湾仔区", "九龙城区", "观塘区", "深水埗区", "黄大仙区", "油尖旺区", "离岛区", "葵青区", "北区", "西贡区", "沙田区", "大埔区", "荃湾区", "屯门区", "元朗区", "将军澳", "坑口", "大围", "火炭", "红磡", "马鞍山", "何文田"],
            tags: [],
            rentType: [
                { text: '房东直租', value: '1' },
                { text: '公寓房源', value: '2' },
                { text: '酒店房源', value: '3' },
            ],
            range: [
                { text: '0-4000', value: '1' },
                { text: '4001-6000', value: '2' },
                { text: '6001-8000', value: '3' },
                { text: '8001-10000', value: '4' },
                { text: '10000以上', value: '5' },
            ],
            houses: [],
            select_section: "",
            select_tag: "",
            select_showType: "",
            select_price: ""
        };
    },

    methods: {
        click_menu(topage) {
            this.$router.push(topage);
        },
        click_detail(id) {
            this.$router.push('/detail?id=' + id);
        },
        get_data() {
            var params = {
                keywords: this.search,
                topage: this.currentpage,
                pagesize: this.pagesize,
                section: this.select_section,
                tag: this.select_tag,
                showType: this.select_showType,
                price: this.select_price
            }
            api.getRequest('house/list',
                params).then(resp => {
                    //   console.log(resp)
                    this.tags = resp.data.tags.tags
                    this.houses = resp.data.data.records
                    this.totalRecord = resp.data.data.total
                    // console.log(this.totalRecord)

                })
        },
        paging(val) {
            // console.log(val)
            this.currentpage = val;
            this.get_data();
        },
        handleSizeChange(val) {
            this.pagesize = val
            this.get_data();
            // console.log(`每页 ${val} 条`);
        },
        empty_key() {
            if (this.search == "") {
                this.get_data()
            }
        },
        click_section(val) {

            this.select_section = val
            this.get_data()
        },
        click_tag(val) {

            this.select_tag = val
            this.get_data()
        },
        click_type(val) {

            this.select_showType = val.value
            this.get_data()
        },
        click_price(val) {
            // console.log(val)
            this.select_price = val
            this.get_data()
        },


    }
}
</script>
<style scoped lang="css" src="./assets/index.css" />
