<template>
    <div>
        <Top_head></Top_head>


        <div class="news_details">
            <div class=" g_cw g_mt">
                <div class="g_title">
                    <h3>{{ detail.title }}</h3>
                    <p class="l2">作者：港专租 &nbsp&nbsp 发布时间：{{ detail.showDate }}</p>
                </div>
                <div class="g_content">
                    <div s class="show-html" v-html="detail.detail"></div>
                </div>
            </div>
        </div>

        <Bottom></Bottom>
    </div>
</template>
<script>

import Top_head from '@/components/Top_head.vue';
import Bottom from "@/components/Bottom.vue";
import * as api from "../../api/api"

export default {
    components: { Top_head, Bottom },
    created: async function () {
        let id = this.$route.query.id
        this.set_id = id
        // console.log(this.set_id)
        this.get_data(id)



    },
    data() {
        return {
            set_id: "",
            detail: []
        }
    },
    methods: {

        get_data(id) {
            var params = {
                id: id
            }
            api.getRequest('content/detail',
                params).then(resp => {
                    // console.log(resp)

                    this.detail = resp.data
                    // console.log(this.detail)
                })
        },
    }
}
</script>

