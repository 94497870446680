<template>
    <div>
        <Top_head></Top_head>

        <div class="news_home">
            <div class="g_cw g_mt">
                <div class="g_title">
                    <h5></h5>
                    <h6>NEWS</h6>
                </div>
                <div class="right">
                    <ul class="list_a">

                    </ul>
                </div>
            </div>



            <div class="news_list">
                <div class="g_cw">
                    <ul class="list">

                        <li v-for="item in news">
                            <a href="javascript:void(0)" @click="click_menu(item.id)">
                                <div class="rq_a">
                                    <p class="t1">{{ item.showDate }}</p>
                                    <p class="xh_a"></p>
                                    <div class="jt_a">
                                        <img class="jt_a" src="images/jt.svg">
                                    </div>
                                </div>
                                <div class="rq_wb">
                                    <div class="rq" style="width: 150px;">
                                        <p class="t1">{{ item.showDate }}</p>
                                        <p class="xh_a"></p>
                                        <div class="jt">
                                            <img class="jt" src="images/jt.svg">
                                        </div>
                                    </div>
                                    <div class="wb" style="width: 724px;">
                                        <h4 class="l1">{{ item.title }}</h4>
                                        <p class="l2">
                                            {{ item.remark }}
                                        </p>
                                    </div>
                                </div>
                                <div class="ct">
                                    <img class="" :src="item.showPic" />
                                </div>
                            </a>
                        </li>









                    </ul>
                </div>

                <div class="pages">

                </div>

            </div>
        </div>
        <Bottom></Bottom>
    </div>
</template>
<script>

import Top_head from '@/components/Top_head.vue';
import Bottom from "@/components/Bottom.vue";
import * as api from "../../api/api"

export default {
    components: { Top_head, Bottom },
    data() {
        return {
            news: []
        }
    },
    created: async function () {
        this.get_data();
    },
    methods: {
        click_menu(id) {
            this.$router.push('/newsDetail?id=' + id);
        },
        get_data() {
            var params = {
              column:30
            }
            api.getRequest('content',
                params).then(resp => {
                    // console.log(resp)
                    this.news = resp.data.records

                })
        },
    }
}
</script>

