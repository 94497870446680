<template>
    <div>
        <!--底部-->
        <div class="footer">

            <div class="bq  clearfix">
                <div class="g_cw tb">

                    <div class="bq_a">
                        <div class="bt">
                            <h3>网站导航</h3>
                            <div class="xh">
                                <p class="xh_a"></p>
                                <p class="xh_b"></p>
                            </div>
                        </div>
                        <ul class="list right">
                            <li><a href="javascript:void(0)" @click="click_menu('')">网站首页</a></li>
                            <li><a href="javascript:void(0)" @click="click_menu('indexList')">加入我们</a></li>
                            <li><a href="javascript:void(0)" @click="click_menu('aboutUs')">关于我们</a></li>
                            <li><a href="javascript:void(0)" @click="click_menu('contactUs')">联系我们</a></li>
                        </ul>
                    </div>


                    <div class="bq_a">
                        <div class="bt">
                            <h3>联系我们</h3>
                            <div class="xh">
                                <p class="xh_a"></p>
                                <p class="xh_b"></p>
                            </div>
                        </div>
                        <ul class="list width">
                            <li>
                                <p><img class="" src="images/co_a.svg">咨询热线：(852)2776 0122</p>
                            </li>

                            <li>
                                <p><img class="" src="images/co_c.svg">联系邮箱：info@green6d.com</p>
                            </li>
                            <li>
                                <p style="font-size: 12px;">https://www.gzzrent.vip is owned and used by </p>
                            </li>
                            <li>
                                <p style="font-size: 12px;"> 港专市场推广（广州）有限公司 GANG ZHUAN GROUP LIMITED</p>
                            </li>
                        </ul>
                    </div>


                    <div class="bq_a ne">
                        <div class="bt">
                            <h3>关注我们</h3>
                            <div class="xh">
                                <p class="xh_a"></p>
                                <p class="xh_b"></p>
                            </div>
                        </div>
                        <div class="sm">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <img src="/img/qrcode.jpg">
                            <p>扫一扫关注微信公众号</p>
                        </div>
                    </div>



                    <div class="bq_a no">
                        <p class="top">
                            分享至:
                            <a href=""><img src="images/bq_ct_a.png"></a>
                            <a href=""><img src="images/bq_ct_b.png"></a>
                            <a href=""><img src="images/bq_ct_c.png"></a>
                            <a href=""><img src="images/bq_ct_d.png"></a>
                        </p>
                    </div>
                </div>

                <div class="bq_b">
                    <div class="g_cw">
                        <div class="left">
                            <p>
                                关注我们:
                                <a href="#"><img src="images/bq_ct_a.png"></a>
                                <a href="#"><img src="images/bq_ct_b.png"></a>
                                <a href="#"><img src="images/bq_ct_c.png"></a>
                                <a href="#"><img src="images/bq_ct_d.png"></a>
                            </p>
                        </div>
                        <div class="right">
                            <p>Copy&nbsp;Right©港专市场推广（广州）有限公司.<a href="https://beian.miit.gov.cn"
                                    target="_blank">粤ICP备2023047997号</a> </p>
                        </div>
                    </div>
                </div>



            </div>
            <div class="box-center">
                <div class="group_83 flex-row">
                    <img class="image_18_1" referrerpolicy="no-referrer" src="../assets/img/minicode.jpg" />
                    <div class="text-group_51 flex-col justify-between">
                        <span class="text_144">扫描关注港专租小程序</span>
                        <span class="text_145">优质房源瞬间掌握</span>
                    </div>
                    <img referrerpolicy="no-referrer" src="../assets/img/miniapp.png" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        click_menu(url) {
            this.$router.replace('/' + url)
        },
    }
}

</script>
<style scoped lang="css">
.group_83 {

    background-color: rgba(255, 255, 255, 1);
    width: 1220px;
    height: 259px;
}

.image_18_1 {
    width: 136px;
    height: 136px;
    margin: 64px 0 0 14px;
}

.text-group_51 {
    width: 230px;
    height: 55px;
    margin: 105px 528px 0 29px;
}

.text_144 {
    width: 230px;
    height: 23px;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 1);
    font-size: 22px;
    font-family: MicrosoftYaHei;
    font-weight: NaN;
    text-align: left;
    white-space: nowrap;
    line-height: 22px;
}

.text_145 {
    width: 151px;
    height: 19px;
    overflow-wrap: break-word;
    color: rgba(102, 102, 102, 1);
    font-size: 18px;
    font-family: MicrosoftYaHeiLight;
    font-weight: 300;
    text-align: left;
    white-space: nowrap;
    line-height: 18px;
    margin-top: 13px;
}

.box-center {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>