<template>
  <div>
    <el-upload class="up-form" :action="action" :on-remove="handleRemove" :on-change="handleAddChange"
      :before-upload="beforeAvatarUpload" :on-success="handleFilUploadSuccess" :limit=this.totalpic
      :on-exceed="handleLimit" :file-list="fileList">
      <el-button size="small" type="primary">上载文件</el-button>
      <div v-if="fileType == 1" slot="tip" class="el-upload__tip">jpg/png 图片，不超過 4MB</div>
      <div v-if="fileType == 2" slot="tip" class="el-upload__tip">pdf/word 文檔，不超過 10MB</div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "upFile",

  props: {

    'showpicEdit': {
      type: Array,
      Default: []
    },
    'total': {
      type: Number,
      Default: 2
    },
    'fileType': {
      ///文件类型1 图片  2 文档  3全部文件
      type: Number,
      Default: 1
    }

  },
  watch: {
    'showpicEdit': {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(newVal) {
        this.fileList = newVal
        this.imgGroup = newVal;
      },
      deep: true
    },

  },
  data() {
    return {
      mtoken: sessionStorage.getItem('token'),
      action: "https://api.gzzrent.vip/api/upload",//"https://apec.microbee.cn/api/upload",//'http://localhost:8082/api/upload',"http://localhost:8091/api/upload/",
      mode: {},
      fileList: this.showpicEdit,
      totalpic: this.total,
      imgGroup: [],
      src: '',
      show: false,
      dialogImageUrl: '',
      dialogVisible: false,
      hideUploadAdd: false,
      mycss: '',
    }
  },
  created: async function () {
    if (!this.total) {
      this.totalpic = 3
    } else {
      this.totalpic = this.total
    }
    if (!this.imgGroup) this.imgGroup = [];
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.src = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      var fileType = this.fileType
      console.log("zzz" + file.type)
      let isPic = true;
      let isLt2M = file.size / 1024 / 1024 < 10;
      if (fileType = 1) {
        if (!(file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/jpeg')) {
          this.$message.error('请上传 JPG 或者 PNG 格式!');
          isPic = false;
        }
        console.log(file.size)
        isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
          this.$message.error('上传文件大小不能超过 4MB!');

        }
      }
      if (fileType = 2) {
        if (!file.type === 'application/pdf' || !file.type === 'application/docx' || !file.type === 'application/doc') {
          this.$message.error('请上传 pdf 或者 word 格式!');
          isPic = false;
        }
        isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
          this.$message.error('上传文件大小不能超过 10MB!');

        }
      }


      // if (!file.type === 'application/pdf' || !file.type === 'image/png' || !file.type === 'image/jpeg')
      // if (!(file.type === 'application/pdf')) {
      //   this.$message.error('请上传PDF文件!');
      //   isJPG = false;
      // } else 


      return isLt2M && isPic;

    },

    upload: function (file) {

      let fd = new FormData()
      fd.append('imgfile', file)

      console.log(fd)

      this.$axios.post('/upload', fd, {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      }).then(response => {
        // console.log(response.data);

        // console.log(this.imgGroup)

        if (response.data.rs === 101) {
          let one = { name: file.name, url: response.data.path, uid: file.uid, type: file.type };
          let data = Object.assign({}, JSON.parse(JSON.stringify(one)));
          console.log(data)
          this.imgGroup.push(data);
          //  console.log(this.imgGroup)
          this.$emit('upFileName', this.imgGroup);
          this.$message({
            message: '上传成功!',
            type: 'success'
          });
        } else {
          this.$message.error('上传失败')
        }


      })
    },
    handleLimit() {
      // console.log(this.totalpic)
      let notice = '最多上传' + this.totalpic + '个';
      this.$message.error(notice)

    },
    handleFilUploadSuccess(res, file, fileList) {
      // console.log(res)
      // console.log(file)
      let one = { name: file.name, url: res.data, uid: file.uid };
      let data = Object.assign({}, JSON.parse(JSON.stringify(one)));
      // console.log(data)
      this.imgGroup.push(data);
      // console.log(this.imgGroup)
      this.$emit('upFileName', this.imgGroup);
      this.$message({
        message: '上传成功!',
        type: 'success'
      });

    },
    handleAddChange(file, fileList) {
      // console.log(this.totalpic)
      if (fileList.length >= this.totalpic) {
        this.mycss = 'hideUpload'
      }
    },
    handleRemove(file) {
      //console.log(file, fileList);
      // console.log(file.uid, fileList);
      let a = file.uid;

      //console.log(fileList.length);
      for (var i = 0; i < this.imgGroup.length; i++) {
        // console.log(this.imgGroup[i].uid)
        if (this.imgGroup[i].uid === a) {
          this.imgGroup.splice(i, 1)
        }

      }
      this.$emit('upFileName', this.imgGroup);
      // console.log(this.imgGroup)
      this.mycss = ''


    },


    modeUpload: function (item) {
      //console.log(item.file);
      this.mode = item.file
      this.upload(this.mode)


    },

  }
}
</script>

<style scoped>
.hideUpload /deep/ .el-upload {
  display: none;
  /* 上传按钮隐藏 */
}

.up-form {
  width: 100%;
  border-radius: 2px;
  border: 1px solid #DADADA;
}
</style>