<template>
  <div class="page flex-col">
    <div class="group_76 flex-col">
      <div class="group_77 flex-col">

        <div class="block_51 flex-row">
          <img class="image_15" referrerpolicy="no-referrer"
            src="./assets/img/psfatrpdvd6pk7k0jhwlrhx5fqk6t4a2mw8048ba1c-edec-44f1-a421-82086baf4e4d.png" />
          <span class="text_147" @click="click_menu('')">首页</span>
          <span class="text_148" @click="click_menu('indexList')">全部房源</span>
          <span class="text_151" @click="click_menu('service')">服务</span>
          <span class="text_152" @click="click_menu('event')">最新活动</span>
          <span class="text_153" @click="click_menu('news')">最新消息</span>
          <span class="text_153" @click="click_menu('newsDetail?id=12')">工作配对</span>


        </div>
        <div class="text-wrapper_150 flex-row"><span class="text_135">优质房源选港专租</span></div>
        <div class="text-wrapper_151 flex-row">
          <span class="text_136">可靠、品质、一站式来港租住服务平台</span>
        </div>
        <div class="text-wrapper_152 flex-row">
          <span class="text_137" @click="click_search(0)">全部</span>
          <span class="text_138" @click="click_search(1)">房东房源</span>
          <span class="text_139" @click="click_search(2)">公寓房源</span>
          <span class="text_140" @click="click_search(3)">酒店房源</span>
        </div>
        <div class="block_52 flex-row">
          <div class="group_81 flex-row justify-end">

            <input type="text" v-model="keyword" style="width: 300px;font-size: 18px;border-style:none;"
              placeholder="欢迎来到香港，您想租住哪里呢？">

            <div class="block_17 flex-col">



            </div>


            <div class="text-wrapper_45 flex-col" @click="search"><span class="text_143">立即查询</span></div>
            <img class="image_16" referrerpolicy="no-referrer" v-if="show_arrow == 0"
              src="./assets/img/psrcwedh2geam53vuxsldojmf7e9u34fj35a5b853-19ec-4953-a589-62ddf04d6992.png" />
            <img v-if="show_arrow == 1" class="image_16" style="margin-left: 108px;" referrerpolicy="no-referrer"
              src="./assets/img/psrcwedh2geam53vuxsldojmf7e9u34fj35a5b853-19ec-4953-a589-62ddf04d6992.png" />
            <img v-if="show_arrow == 2" class="image_16" style="margin-left: 260px;" referrerpolicy="no-referrer"
              src="./assets/img/psrcwedh2geam53vuxsldojmf7e9u34fj35a5b853-19ec-4953-a589-62ddf04d6992.png" />
            <img v-if="show_arrow == 3" class="image_16" style="margin-left: 400px;" referrerpolicy="no-referrer"
              src="./assets/img/psrcwedh2geam53vuxsldojmf7e9u34fj35a5b853-19ec-4953-a589-62ddf04d6992.png" />

          </div>
        </div>

        <div class="box-center">
          <div class="block_54 flex-row">
            <div class="group_172 flex-col"></div>
            <div class="group_173 flex-col"></div>
            <span class="paragraph_3">
              提供最优质的服务
              <br />
            </span>
            <div class="group_174 flex-col"></div>
            <div class="group_175 flex-col"></div>
          </div>
        </div>

      </div>
    </div>
    <div class="top-head">
      <div class="block_50 flex-row justify-end">
        <div class="image-text_87 flex-row">
          <div class="text-group_46 flex-col">
            <span class="text_90">GO</span>
            <span class="text_91">01</span>
            <span class="text_92">房东直租</span>
          </div>
          <img class="image_9" referrerpolicy="no-referrer"
            src="./assets/img/pso9j57ixr3i6a2q08eykrsjvcf988yy659ba2900-6891-40c3-a8a9-308bc63414ff.png" />
        </div>
        <div class="image-text_88 flex-col justify-between">
          <div class="group_52 flex-col">
            <div class="box_13 flex-col"></div>
          </div>
          <div class="text-group_47 flex-row">
            <span class="text_93">02</span>
            <span class="text_94">清洁服务</span>
          </div>
        </div>
        <div class="image-text_89 flex-col justify-between">
          <div class="box_14 flex-col">
            <div class="group_53 flex-col"></div>
          </div>
          <div class="text-group_48 flex-row">
            <span class="text_95">03</span>
            <span class="text_96">拎包入住</span>
          </div>
        </div>
        <div class="image-text_90 flex-col justify-between">
          <div class="box_15 flex-col align-center">
            <div class="group_54 flex-col">
              <div class="block_8 flex-col"></div>
            </div>
          </div>
          <div class="text-group_49 flex-row">
            <span class="text_97">04</span>
            <span class="text_98">维修优享</span>
          </div>
        </div>
        <!-- <div class="box_16 flex-col"></div> -->
      </div>
    </div>

    <div>
      <div class="w">
        <el-carousel height="686px">
          <el-carousel-item v-for="(item, index) in list_img" :key="index">
            <h3 class="small">
              <img :src="item.url" alt />
            </h3>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="box-center">
      <div class="block_33 flex-row">
        <div class="group_5 flex-col"></div>
        <div class="group_6 flex-col"></div>
        <span class="text_3">热门房源区域</span>
        <div class="group_7 flex-col"></div>
        <div class="group_8 flex-col"></div>
      </div>
    </div>
    <div class="box-center">
      <div class="block_34 flex-row">
        <div class="block_1 flex-col"></div>
        <div class="text-wrapper_1">
          <span class="text_4">房源一键</span>
          <span class="text_5">GO</span>
        </div>
        <div class="block_2 flex-col"></div>
      </div>
    </div>
    <div class="box-center">
      <div style="width: 1330px;">
        <div class="list-box-3">
          <div class="block_3 flex-col">
            <div class="group_11 flex-col">
              <img src="../lanhu_pcshouye/assets/img/wj.jpg" style="border-radius: 12px 12px 0px 0px;">
            </div>
            <div class="text-wrapper_135 flex-row justify-between">
              <span class="text_6">湾仔区</span>
              <span class="text_7">/&nbsp;Wanchai</span>
            </div>
          </div>
        </div>
        <div class="list-box-3">
          <div class="block_3 flex-col">
            <div class="group_11 flex-col">
              <img src="../lanhu_pcshouye/assets/img/kt.jpg" style="border-radius: 12px 12px 0px 0px;">
            </div>
            <div class="text-wrapper_135 flex-row justify-between">
              <span class="text_6">观塘区</span>
              <span class="text_7">/&nbsp;Kwun&nbsp;Tong</span>
            </div>
          </div>
        </div>
        <div class="list-box-3">
          <div class="block_3 flex-col">
            <div class="group_11 flex-col">
              <img src="../lanhu_pcshouye/assets/img/yjw.jpg" style="border-radius: 12px 12px 0px 0px;">
            </div>
            <div class="text-wrapper_135 flex-row justify-between">
              <span class="text_6">油尖旺区</span>
              <span class="text_7">/&nbsp;Yau&nbsp;Tsim&nbsp;Mong</span>
            </div>
          </div>
        </div>
        <div class="list-box-3">
          <div class="block_3 flex-col">
            <div class="group_11 flex-col">
              <img src="../lanhu_pcshouye/assets/img/zxq.jpg" style="border-radius: 12px 12px 0px 0px;">
            </div>
            <div class="text-wrapper_135 flex-row justify-between">
              <span class="text_6">中西区</span>
              <span class="text_7">/&nbsp;Central&nbsp;and&nbsp;Western</span>
            </div>
          </div>
        </div>
        <div class="list-box-3">
          <div class="block_3 flex-col">
            <div class="group_11 flex-col"><img src="../lanhu_pcshouye/assets/img/nq.jpg"
                style="border-radius: 12px 12px 0px 0px;"></div>
            <div class="text-wrapper_135 flex-row justify-between">
              <span class="text_6">南区</span>
              <span class="text_7">/&nbsp;Southern</span>
            </div>
          </div>
        </div>
        <div class="list-box-3">
          <div class="block_3 flex-col">
            <div class="group_11 flex-col">
              <img src="../lanhu_pcshouye/assets/img/klc.jpg" style="border-radius: 12px 12px 0px 0px;">
            </div>
            <div class="text-wrapper_135 flex-row justify-between">
              <span class="text_6">九龙城区</span>
              <span class="text_7">/&nbsp;Kowloon</span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="box-middle-sologan"></div>
    <div class="box-center">


      <div class="block_37 flex-row">
        <div class="section_2 flex-col"></div>
        <div class="section_3 flex-col"></div>
        <span class="text_18">热搜精选公寓</span>
        <div class="section_4 flex-col"></div>
        <div class="section_5 flex-col"></div>
      </div>

    </div>
    <div class="box-center">
      <div class="block_38 flex-row">
        <div class="group_21 flex-col"></div>
        <span class="text_19">好房为您推荐</span>
        <div class="group_22 flex-col"></div>
      </div>
    </div>
    <div class="box-center">
      <div class="block_39 flex-row justify-between">
        <!-- <div class="box_85 flex-col">
          <div class="block_7 flex-col"></div>
          <span class="text_20">香港大学</span>
        </div> -->
        <span class="text_21" v-for="item in tags">{{ item.tagName }}</span>

      </div>

    </div>

    <div class="box-center">
      <div style="width: 1440px;">
        <div class="list-box-3" v-for="item in houses" @click="click_detail(item.id)">
          <div class="group_25 flex-col">
            <div class="section_6 flex-col">
              <img :src="item.image" style="border-radius: 12px 12px 0px 0px;width: 420px;height: 260px;">
            </div>
            <span class="text_28">{{ item.title }}</span>
            <div class="group_199 flex-row">
              <div class="image-text_81 flex-row justify-between">
                <img class="image_2" referrerpolicy="no-referrer"
                  src="./assets/img/psjq1yyrtqh3pg4ybyj2pgxcvnplck4b2lfbbfd6998-2a41-4550-9b13-cc081d0a0cb9.png" />
                <span class="text-group_2">{{ item.section }}</span>
              </div>


            </div>
            <div class="group_200 flex-row ">
              <el-tag v-for="tags in item.tagNames" style="margin-right: 15px;">{{ tags }}</el-tag>
            </div>
            <div class="text-wrapper_11">
              <span class="text_34">HK$</span>
              <span class="text_35">{{ item.price }}/月</span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="box-center">
      <div class="image-text_80 flex-row justify-between" @click="click_menu('indexList')">
        <span class="text-group_1">探索更多好房</span>
        <img class="thumbnail_1" referrerpolicy="no-referrer"
          src="./assets/img/psbygte66p8xfo9681cba478j9mh9837ose8c14748c-2303-4750-8854-fa548a9289a7.png" />
      </div>
    </div>
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="grid-content bg-purple"></div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple"></div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple"></div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple"></div>
      </el-col>
    </el-row>
    <Bottom></Bottom>


  </div>
</template>
<script>

import * as api from "../../api/api"

import Bottom from "@/components/Bottom.vue";
export default {
  components: { Bottom },
  data() {
    return {
      constants: {},
      show_arrow: 0,
      list_img: [
        { url: require('./assets/img/ban1.png') },
        { url: require('./assets/img/ban2.png') },
        { url: require('./assets/img/ban3.png') },
        { url: require('./assets/img/ban4.png') },

      ],
      dialogVisible: false,
      labelPosition: 'left',
      formLabelAlign: {
        name: '',
        region: '',
        type: ''
      },
      tags: [],
      houses: [],
      keyword: "",
      rentType: ""


    };
  },
  created: async function () {
    this.get_data();
  },
  methods: {
    click_menu(url) {

      this.$router.push('/' + url)
    },
    search() {
      this.$router.push('/indexList?' + 'keyword=' + this.keyword + "&rentType=" + this.rentType)
    },
    get_data() {
      var params = {

      }
      api.getRequest('house/list',
        params).then(resp => {
          // console.log(resp)
          this.tags = resp.data.tags.tags
          this.houses = resp.data.data.records
          // console.log(this.houses)

        })
    },
    click_search(i) {
      this.show_arrow = i
      if (i != 0) {
        this.rentType = i
      }


    },
    handleClose() {

    },
    click_detail(id) {
      this.$router.push('/detail?id=' + id);
    },

  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style>
.w {
  margin: 0 auto;
  width: 1343px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 1;
  line-height: 300px;
  margin: 0;
  text-align: center;
}



.small img {
  width: 100%;
  height: 100%;
}

.el-carousel__container {
  width: 1343px;
  height: 686px;
}

.details_class .el-dialog__body {
  padding: 0px;
  margin: 0px;
}

.details_class .el-dialog__header {
  padding: 0px;
  margin: 0px;
}
</style>
