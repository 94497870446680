<template>
    <div>
        <el-image :src="picUrl" v-if="showPic == true">
            <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
            </div>
        </el-image>
        <div>
            <el-button @click="reUpload" v-if="editting == true">重新上传图片</el-button>
            <upFile @upFileName="showpicVal" :showpicEdit="showpicEdit" :total="1" :fileType="1" v-if="re_upload == true">
            </upFile>
        </div>
    </div>
</template>
<script>
import upFile from "./upFile.vue";
export default {
    props: {
        'picUrl_get': {
            type: String,
            Default: ""
        },

    },
    watch: {
        'picUrl_get': {
            immediate: true,
            handler(newVal) {
                if (newVal != "" && newVal != undefined) {
                    this.showPic = true
                    this.re_upload = false
                    this.editting = true
                    this.picUrl = this.serverUrl + newVal
                } else {
                    this.showPic = false
                    this.re_upload = true
                    this.editting = false
                    this.picUrl = ""
                }

            },
            deep: true
        },

    },
    data() {
        return {
            re_upload: true,
            editting: false,
            picUrl: "",
            showpicEdit: [],
            serverUrl: this.$root.serverUrl,
            formLabelWidth: "120px",
            showPic: false

        }
    },
    components: { upFile },
    methods: {
        reUpload() {
            this.re_upload = true
        },
        showpicVal(data) {

            this.showpicEdit = data
            this.showPic = true
            this.picUrl = this.serverUrl + this.showpicEdit[0].url

            this.$emit('upFileName', this.showpicEdit[0].url);
            console.log(data)
        },
    }
}
</script>