import axios from "axios"
import { Message } from "element-ui"
import router from "../router"



// axios.defaults.headers.common['token'] = token;
axios.interceptors.request.use(config => {
    // console.log("前置拦截")
    // var token = sessionStorage.getItem("token")
    // console.log("通用" + token)
    // var Authorization = sessionStorage.getItem("Authorization")
    // console.log(token)
    // config.headers.Token = token
    // config.headers.Authorization = Authorization
    // 可以统一设置请求头
    return config
})
axios.interceptors.response.use(success => {
    // console.log(success.data.data)
    // console.log(success.status)
    if (success.status && success.status == 200) {
        if (success.data.code == 500 || success.data.code == 401 || success.data.code == 403) {
            Message.error({ message: success.data.message })
            return
        }
        if (success.data.message) {
            return success.data
            // Message.success({message:success.data.message})
        }
        if (success.data) {
            return success.data
            // Message.success({message:success.data.message})
        }
        else {
            // router.replace('/')
            Message.warning({ message: "请检查网络" })

        }
    }

}, error => {
    // console.log(error.status)
    if (error.response.code == 504 || error.response.code == 404) {
        Message.error({ message: "服务器错误，请联系管理员或者供应商。" })
    } else if (error.response.code == 403) {
        Message.warning({ message: "你没有访问权限，请联系管理员。" })
    }
    else if (error.response.code == 401) {
        Message.error({ message: "请检查网络" })
        // this.$router.replace('/')
    } else {
        // if (error.response.data.message){
        //     Message.error({message:error.response.data.message})
        // }else{
        // router.replace('/')
        Message.warning({ message: "请检查网络。" })
        // }
    }
})

let base = '' //'http://localhost:8091/api/admin'
// let base = "https://api.gzzrent.vip/api/admin/"//

let serverUrl = "https://api.gzzrent.vip/upload_file/" //'https://apec.microbee.cn/upload_file/'


function check_status() {
    if (token == "") {

        this.$message.console.warn('请检查网络');
        this.$router.replace('/')
        return false
    }
    else {
        return true
    }

}

export const postRequest = (url, params) => {

    return axios({
        method: 'post',
        // headers: headers,
        url: `${base}${url}`,
        data: params
    })
}

export const getRequest = (url, params) => {

    return axios({
        method: 'get',
        url: `${base}${url}`,
        params: params,
    })
}

export const putRequest = (url, params) => {
    return axios({
        method: 'PUT',
        // headers: headers,
        url: `${base}${url}`,
        data: params,
    })
}

export const deleteRequest = (url, params) => {
    return axios({
        method: 'delete',
        // headers: headers,
        url: `${base}${url}`,
        data: params,
    })
}

export const return_jason = (str) => {
    if (str !== "" && str != "[]") {
        return JSON.parse(str)
    }
    else {
        return []
    }
}



export const return_string = (json) => {
    if (json !== "" && json != []) {
        return JSON.stringify(json)
    }
    else {
        return ""
    }
}

export const return_pics_string = (json) => {
    let tempPic = JSON.stringify(json);
    let edit_pics = JSON.parse(tempPic)

    edit_pics.forEach(function (item) {
        item.url = item.url.replace(serverUrl, "")
    });
    return JSON.stringify(edit_pics)
}

export const return_pics_json = (json) => {

    json.forEach(function (item) {
        item.url = serverUrl + item.url
    });
    return json
}
export const return_pics_json_just_item = (json) => {
    var new_json = []
    json.forEach(function (item) {
        new_json.push(item.fileUrl)
    });
    return new_json
}