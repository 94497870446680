<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="block_2 flex-col">
        <div class="group_1 flex-col justify-end">
          <div class="block_18 flex-row">
            <div class="section_2 flex-col"></div>
            <span class="text_1">迷一样的租客</span>
            <div class="section_3 flex-col"></div>
            <span class="text_2">退出登录</span>
            <div class="section_4 flex-col"></div>
            <span class="text_3">返回官网</span>
          </div>
          <img
            class="image_1"
            referrerpolicy="no-referrer"
            src="./assets/img/psfesskxeyl6n3ax4ncr43pgwznuzv5plbfea80d82b-ab91-4cdc-bca2-968cc38105f5.png"
          />
        </div>
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/pshr0l022wpojxhochj3tqdf3kqk0ov6yj357cfe8e-47b9-45f1-b162-546763ee0d25.png"
        />
      </div>
      <div class="group_41 flex-col">
        <div class="group_42 flex-row justify-between">
          <div class="box_1 flex-col">
            <div class="box_48 flex-row justify-between">
              <div class="image-wrapper_1 flex-col">
                <img
                  class="thumbnail_1"
                  referrerpolicy="no-referrer"
                  src="./assets/img/ps45dnp44te8gogrea4bmwlqpyxhojsn2f89b56e497-42f8-464c-9935-e525b11a0d7d.png"
                />
              </div>
              <span class="text_4">个人中心</span>
            </div>
            <div class="box_3 flex-col">
              <span class="text_5">帐号信息</span>
              <div class="section_5 flex-col"></div>
            </div>
            <span class="text_6">资料编辑</span>
            <div class="box_49 flex-row justify-between">
              <div class="image-wrapper_2 flex-col">
                <img
                  class="thumbnail_2"
                  referrerpolicy="no-referrer"
                  src="./assets/img/ps0jaq5lel64oewwmze9nvuo5gbuqhsaii78724d9a6-b3d7-492f-9152-d4eb8e58a41a.png"
                />
              </div>
              <span class="text_7">房源订单</span>
            </div>
            <span class="text_8">房源租赁</span>
            <span class="text_9">费用收据</span>
            <div class="box_50 flex-row justify-between">
              <div class="image-wrapper_3 flex-col">
                <img
                  class="thumbnail_3"
                  referrerpolicy="no-referrer"
                  src="./assets/img/pswgctysg0lciarqhk4zek8knbp6u7p7dkk2d2e4389-1c66-4bdf-ae7b-6e2b24a2c4ad.png"
                />
              </div>
              <span class="text_10">其他功能</span>
            </div>
            <span class="text_73">消息中心</span>
            <span class="text_11">活动中心</span>
            <span class="text_12">我的积分</span>
            <span class="text_13">浏览足迹</span>
            <span class="text_14">我的关注</span>
          </div>
          <div class="box_51 flex-col justify-between">
            <div class="section_6 flex-row">
              <div class="group_3 flex-col">
                <div class="image-wrapper_4 flex-col">
                  <img
                    class="thumbnail_4"
                    referrerpolicy="no-referrer"
                    src="./assets/img/psm7x831jxygo8ybm88lc4ol9wcwrw88v15914d73c-332f-46eb-a9ba-3d92969f5838.png"
                  />
                </div>
              </div>
              <div class="box_52 flex-col justify-between">
                <span class="text_15">迷一样的租客</span>
                <div class="group_43 flex-row justify-between">
                  <img
                    class="label_1"
                    referrerpolicy="no-referrer"
                    src="./assets/img/pso1pgp28dl1i6vzl8d4a7lch6iolebe470311d803-d520-4863-aa33-a434ca0342ff.png"
                  />
                  <span class="text_16">普通会员</span>
                </div>
              </div>
              <div class="text-wrapper_57 flex-col justify-between">
                <span class="text_17">2</span>
                <span class="text_18">&nbsp;&nbsp;我的积分</span>
              </div>
              <div class="text-wrapper_58 flex-col justify-between">
                <span class="text_19">10</span>
                <span class="text_20">浏览足迹</span>
              </div>
              <div class="text-wrapper_59 flex-col justify-between">
                <span class="text_21">6</span>
                <span class="text_22">我的关注</span>
              </div>
            </div>
            <div class="section_7 flex-col">
              <span class="text_23">房源租赁</span>
              <div class="block_19 flex-row">
                <div class="box_7 flex-col"><div class="section_8 flex-col"></div></div>
                <div class="block_20 flex-col justify-between">
                  <span class="text_24">科学园/海日湾/宽敞明亮....</span>
                  <div class="group_44 flex-row">
                    <span class="text_25">大围/沙田</span>
                    <div class="group_6 flex-col"></div>
                    <span class="text_26">单间</span>
                    <div class="group_7 flex-col"></div>
                    <span class="text_27">130平方呎</span>
                  </div>
                </div>
                <div class="text-wrapper_60 flex-col justify-between">
                  <span class="text_28">预约床位</span>
                  <span class="text_29">租赁日期</span>
                </div>
                <div class="block_21 flex-col justify-between">
                  <div class="text-wrapper_61 flex-row">
                    <span class="text_30">床位A</span>
                    <span class="text_31">租赁费用</span>
                    <span class="text_32">6500HK$/月</span>
                  </div>
                  <span class="text_33">2023/03/09&nbsp;-&nbsp;2023/09/09</span>
                </div>
                <span class="text_34">预登记</span>
                <span class="text_35">查看详情&nbsp;&gt;</span>
              </div>
              <img
                class="image_3"
                referrerpolicy="no-referrer"
                src="./assets/img/pssajmoq0impozf6dvhxkcycizr1l2br22ac2d78bc-1958-4fe7-b5ab-c43dfaafe7d2.png"
              />
              <div class="block_22 flex-row">
                <div class="box_10 flex-col"><div class="block_7 flex-col"></div></div>
                <div class="section_17 flex-col justify-between">
                  <span class="text_36">科学园/海日湾/宽敞明亮....</span>
                  <div class="group_45 flex-row">
                    <span class="text_37">大围/沙田</span>
                    <div class="block_8 flex-col"></div>
                    <span class="text_38">单间</span>
                    <div class="block_9 flex-col"></div>
                    <span class="text_39">130平方呎</span>
                  </div>
                </div>
                <div class="text-wrapper_62 flex-col justify-between">
                  <span class="text_40">预约床位</span>
                  <span class="text_41">租赁日期</span>
                </div>
                <div class="section_18 flex-col justify-between">
                  <div class="text-wrapper_63 flex-row">
                    <span class="text_42">床位A</span>
                    <span class="text_43">租赁费用</span>
                    <span class="text_44">6500HK$/月</span>
                  </div>
                  <span class="text_45">2023/03/09&nbsp;-&nbsp;2023/09/09</span>
                </div>
                <span class="text_46">待付款</span>
                <span class="text_47">查看详情&nbsp;&gt;</span>
              </div>
              <img
                class="image_4"
                referrerpolicy="no-referrer"
                src="./assets/img/pstrp29gghteub2xde56ufe6bz3o94mfg52c232725-4403-4c77-9f27-786c650b10da.png"
              />
              <div class="block_23 flex-row">
                <div class="box_13 flex-col"><div class="box_14 flex-col"></div></div>
                <div class="box_53 flex-col justify-between">
                  <span class="text_48">科学园/海日湾/宽敞明亮....</span>
                  <div class="box_54 flex-row">
                    <span class="text_49">大围/沙田</span>
                    <div class="block_11 flex-col"></div>
                    <span class="text_50">单间</span>
                    <div class="block_12 flex-col"></div>
                    <span class="text_51">130平方呎</span>
                  </div>
                </div>
                <div class="text-wrapper_64 flex-col justify-between">
                  <span class="text_52">预约床位</span>
                  <span class="text_53">租赁日期</span>
                </div>
                <div class="box_55 flex-col justify-between">
                  <div class="text-wrapper_65 flex-row">
                    <span class="text_54">床位A</span>
                    <span class="text_55">租赁费用</span>
                    <span class="text_56">6500HK$/月</span>
                  </div>
                  <span class="text_57">2023/03/09&nbsp;-&nbsp;2023/09/09</span>
                </div>
                <span class="text_58">已登记</span>
                <span class="text_59">查看详情&nbsp;&gt;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block_13 flex-col">
        <div class="group_46 flex-row">
          <div class="box_17 flex-row">
            <div class="image-text_5 flex-row justify-between">
              <img
                class="label_2"
                referrerpolicy="no-referrer"
                src="./assets/img/pslwmqnaa6x1jqey3jgkgiovvvcnt30adgb5a4cdc0-e26e-4a10-aea3-18ddfbcf3487.png"
              />
              <span class="text-group_1">简体中文</span>
            </div>
            <img
              class="thumbnail_5"
              referrerpolicy="no-referrer"
              src="./assets/img/ps5b28exksomb6pbflcku924n1bgc7y31b0a3b5e37-f0cf-4fe9-bfdd-a0cc48951726.png"
            />
          </div>
          <span class="text_60">港专租</span>
          <span class="text_61">合作咨询</span>
          <span class="text_62">其他</span>
          <span class="text_63">订阅邮件咨询</span>
        </div>
        <div class="group_47 flex-row">
          <div class="text-wrapper_66 flex-col justify-between">
            <span class="text_64">关于我们</span>
            <span class="text_65">官方微信</span>
          </div>
          <div class="text-wrapper_67 flex-col justify-between">
            <span class="text_66">成为合作伙伴</span>
            <span class="text_67">房源合作</span>
          </div>
          <div class="text-wrapper_68 flex-col justify-between">
            <span class="text_68">常见问题</span>
            <span class="text_69">加入我们</span>
          </div>
          <div class="box_18 flex-row">
            <span class="text_70">输入邮件地址</span>
            <div class="block_14 flex-col">
              <div class="image-wrapper_5 flex-col">
                <img
                  class="image_5"
                  referrerpolicy="no-referrer"
                  src="./assets/img/psejxxprh9g16we4ue9klzxiyj011n0jp8q1dc53f0b-eeb6-4848-b0e3-428e8e0fa07c.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="text-wrapper_69 flex-row"><span class="text_71">虚位以待</span></div>
        <div class="text-wrapper_70 flex-row"><span class="text_72">Copy&nbsp;Right©中国香港港专租有限公司</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />