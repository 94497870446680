var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"footer"},[_c('div',{staticClass:"bq clearfix"},[_c('div',{staticClass:"g_cw tb"},[_c('div',{staticClass:"bq_a"},[_vm._m(0),_c('ul',{staticClass:"list right"},[_c('li',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.click_menu('')}}},[_vm._v("网站首页")])]),_c('li',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.click_menu('indexList')}}},[_vm._v("加入我们")])]),_c('li',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.click_menu('aboutUs')}}},[_vm._v("关于我们")])]),_c('li',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.click_menu('contactUs')}}},[_vm._v("联系我们")])])])]),_vm._m(1),_vm._m(2),_vm._m(3)]),_vm._m(4)]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bt"},[_c('h3',[_vm._v("网站导航")]),_c('div',{staticClass:"xh"},[_c('p',{staticClass:"xh_a"}),_c('p',{staticClass:"xh_b"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bq_a"},[_c('div',{staticClass:"bt"},[_c('h3',[_vm._v("联系我们")]),_c('div',{staticClass:"xh"},[_c('p',{staticClass:"xh_a"}),_c('p',{staticClass:"xh_b"})])]),_c('ul',{staticClass:"list width"},[_c('li',[_c('p',[_c('img',{attrs:{"src":"images/co_a.svg"}}),_vm._v("咨询热线：(852)2776 0122")])]),_c('li',[_c('p',[_c('img',{attrs:{"src":"images/co_c.svg"}}),_vm._v("联系邮箱：info@green6d.com")])]),_c('li',[_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v("https://www.gzzrent.vip is owned and used by ")])]),_c('li',[_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v(" 港专市场推广（广州）有限公司 GANG ZHUAN GROUP LIMITED")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bq_a ne"},[_c('div',{staticClass:"bt"},[_c('h3',[_vm._v("关注我们")]),_c('div',{staticClass:"xh"},[_c('p',{staticClass:"xh_a"}),_c('p',{staticClass:"xh_b"})])]),_c('div',{staticClass:"sm"},[_c('span'),_c('span'),_c('span'),_c('span'),_c('img',{attrs:{"src":"/img/qrcode.jpg"}}),_c('p',[_vm._v("扫一扫关注微信公众号")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bq_a no"},[_c('p',{staticClass:"top"},[_vm._v(" 分享至: "),_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":"images/bq_ct_a.png"}})]),_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":"images/bq_ct_b.png"}})]),_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":"images/bq_ct_c.png"}})]),_c('a',{attrs:{"href":""}},[_c('img',{attrs:{"src":"images/bq_ct_d.png"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bq_b"},[_c('div',{staticClass:"g_cw"},[_c('div',{staticClass:"left"},[_c('p',[_vm._v(" 关注我们: "),_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":"images/bq_ct_a.png"}})]),_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":"images/bq_ct_b.png"}})]),_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":"images/bq_ct_c.png"}})]),_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":"images/bq_ct_d.png"}})])])]),_c('div',{staticClass:"right"},[_c('p',[_vm._v("Copy Right©港专市场推广（广州）有限公司."),_c('a',{attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v("粤ICP备2023047997号")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-center"},[_c('div',{staticClass:"group_83 flex-row"},[_c('img',{staticClass:"image_18_1",attrs:{"referrerpolicy":"no-referrer","src":require("../assets/img/minicode.jpg")}}),_c('div',{staticClass:"text-group_51 flex-col justify-between"},[_c('span',{staticClass:"text_144"},[_vm._v("扫描关注港专租小程序")]),_c('span',{staticClass:"text_145"},[_vm._v("优质房源瞬间掌握")])]),_c('img',{attrs:{"referrerpolicy":"no-referrer","src":require("../assets/img/miniapp.png")}})])])
}]

export { render, staticRenderFns }