<template>
    <div>
        <Top_head></Top_head>

        <div class="contact">
            <div class="g_cw g_mt">
                <div class="g_title">
                    <h5>联系我们</h5>
                    <h6>CONTACT US</h6>
                </div>
            </div>
            <div class="contact_xq">
                <div class="g_cw">
                    <div class="left">
                        <ul class="list">
                            <li>
                                <div class="cn_tb">
                                    <img class="contact-icon" src="images/co_a.svg">
                                </div>
                                <div class="mode">
                                    <p>(852)2776 0122</p>
                                </div>
                            </li>
                            <li>
                                <div class="cn_tb">
                                    <img class="contact-icon" src="images/co_c.svg">
                                </div>
                                <div class="mode">
                                    <p>info@green6d.com</p>
                                </div>
                            </li>
                            <li>
                                <div class="cn_tb">
                                    <img class="contact-icon" src="images/co_b.svg">
                                </div>
                                <div class="mode">
                                    <p>www.green6d.com</p>
                                </div>
                            </li>
                            <li class="no">
                                <div class="cn_tb">
                                    <img class="contact-icon" src="images/co_d.svg">
                                </div>
                                <div class="mode">
                                    <p class="no">香港尖沙咀柯士甸道20号</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="right">
                        <img class="cn_ct" src="../../assets/img/map.png">
                    </div>
                </div>
            </div>
        </div>
        <Bottom></Bottom>
    </div>
</template>
<script>

import Top_head from '@/components/Top_head.vue';
import Bottom from "@/components/Bottom.vue";


export default {
    components: { Top_head, Bottom }
}
</script>

