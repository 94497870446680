<template>
  <div style="width: 1920px;
                          height: 718px;
                          background: linear-gradient(180deg, #E2F3FF, #FFFFFF);">
    <Top_head></Top_head>
    <div class="box-center">
      <div class="section_4 flex-col">
        <div class="section_23 flex-row">
          <div class="box_42 flex-col"></div>
          <div class="box_43 flex-col"></div>
          <span class="text_52">为什么选择港专租？</span>
          <div class="box_44 flex-col"></div>
          <div class="box_45 flex-col"></div>
        </div>
        <div class="section_24 flex-row">
          <div class="group_6 flex-col"></div>
          <span class="text_53">因为我们有最专业的技术团队与客服服务</span>
          <div class="group_7 flex-col"></div>
        </div>
        <div class="section_25 flex-row">
          <div class="image-wrapper_2 flex-col">
            <img class="label_3" referrerpolicy="no-referrer"
              src="./assets/img/psglgjw5b2oil5u6eafogdlda6uuys4wa0950dce9a4-1007-455d-b930-6c8f3f728077.png" />
          </div>
          <div class="flex-col">
            <img class="label_5" referrerpolicy="no-referrer"
              src="./assets/img/psjs608tgo7df3mqweh50jo8bbnb0c5udc49342a5f-c037-4ee8-b123-57412ea6f432.png" />
            <img class="thumbnail_13" referrerpolicy="no-referrer"
              src="./assets/img/pslfvdcy0soxqm3ftfzzso3xz5fdfpa8lb076b09f6-18f7-4236-83ea-b951e640601b.png" />
          </div>

          <div class="image-wrapper_3 flex-col">
            <img class="thumbnail_14" referrerpolicy="no-referrer"
              src="./assets/img/psrgtuyxjgemrfj70ffa9nz8fr1epr31sgn2f13bba0-9aeb-4275-870d-e33d9a1f3b53.png" />
          </div>
          <div class="group_9 flex-col">
            <div class="group_10 flex-col"></div>
          </div>
          <div class="image-wrapper_4 flex-col">
            <img class="label_4" referrerpolicy="no-referrer"
              src="./assets/img/psaudjuuxygo5alflrgqaf3fk5khsqqnr1c90ac6667-8883-4317-a983-aabf97f14a70.png" />
          </div>
        </div>
        <div class="text-wrapper_52 flex-row justify-between">
          <span class="text_54">24小时客服</span>
          <span class="text_55">最新房源信息</span>
          <span class="text_56">大数据找房</span>
          <span class="text_57">专业家修服务</span>
          <span class="text_58">无忧登记入住</span>
        </div>

      </div>
    </div>
    <div class="box-center">
      <div style="width: 1000px;"><el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item title="房东直签租约" name="1">
            <div>每份租约均与房东签署，并会确保送至政府相关部门打厘印作实</div>
            <div>服务介绍</div>
          </el-collapse-item>
          <el-collapse-item title="提供一站式『公寓管理』服务" name="2">
            <div></div>
            <div>
              每周一次清洁
              全年维修服务
            </div>
          </el-collapse-item>
          <el-collapse-item title="拎包进住" name="3">
            <div></div>
            <div>
              包『零甲醛』家具、电器等, 拎包进住
            </div>
          </el-collapse-item>
          <el-collapse-item title="无限上网" name="4">
            <div></div>
            <div>
              24小时免费WIFI提供
            </div>
          </el-collapse-item>
          <el-collapse-item title="水电煤" name="5">
            <div></div>
            <div>
              食用水过滤, 确保饮用到优质水源
              开通水、电、煤服务
            </div>
          </el-collapse-item>
          <el-collapse-item title="淋浴间" name="6">
            <div></div>
            <div>
              浴淋间设有”维他命”过滤水器, 保护你每一吋肌肤
            </div>
          </el-collapse-item>
          <el-collapse-item title="卫生间" name="7">
            <div></div>
            <div>
              马桶UV杀菌, 360保障你的健康
            </div>
          </el-collapse-item>
          <el-collapse-item title="客服服务" name="8">
            <div></div>
            <div>
              朝10晚6客户服务
            </div>
          </el-collapse-item>
          <el-collapse-item title="地理位置" name="9">
            <div></div>
            <div>
              单位遍布全港和大院校网, 涵盖各类型屋苑、洋楼、唐楼、酒店、服务式住宅等
            </div>
          </el-collapse-item>
        </el-collapse></div>
    </div>



    <Bottom></Bottom>
  </div>
</template>
<script>
import Top_head from '../../components/Top_head.vue';
import Bottom from "@/components/Bottom.vue";
export default {
  components: { Top_head, Bottom },
  data() {
    return {
      activeNames: ['1']
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />