<template>
  <div class="page flex-col">
    <div class="box-center">
      <div class="box_1 flex-row">
        <img class="image_1" referrerpolicy="no-referrer"
          src="./assets/img/psh8aaz1zuihxet9mlo45hkckwr1wdz7omfde71c38-64f8-4fb2-ab9e-585e7949ed5b.png" />
        <span class="text_1">首页</span>
        <span class="text_2">房东房源</span>
        <span class="text_3">公寓房源</span>
        <span class="text_4">酒店房源</span>
        <span class="text_5">服务</span>
        <span class="text_6">活动</span>
        <span class="text_7">最新消息</span>
        <span class="text_8">登录</span>

        <div class="group_1 flex-col"></div>
      </div>
    </div>
    <div class="block_1 flex-col">

      <div class="section_17 flex-col">
        <div class="block_14 flex-row justify-between">
          <div class="text-wrapper_1">
            <span class="text_9">首页&nbsp;/</span>
            <span class="text_10">房东房源</span>
          </div>

        </div>
        <div class="section_1 flex-row justify-end">
          <span class="text_11">欢迎来到香港，您想租住哪里呢？</span>
          <div class="box_3 flex-col"></div>
          <img class="thumbnail_2" referrerpolicy="no-referrer"
            src="./assets/img/ps8ittgixkjh3ml03djf22xtmvx5wbw3bea9aed266-80f7-43df-b23a-d77ad24ce952.png" />
          <span class="text_12">地图房源</span>
          <div class="text-wrapper_2 flex-col"><span class="text_13">立即查询</span></div>
          <img class="label_2" referrerpolicy="no-referrer"
            src="./assets/img/psmsa644w0wff7mq4w95psyue8nmlpuo75ae43fb24f-ebbe-49b8-9d4e-cc9ec3350cff.png" />
        </div>
        <div class="section_2 flex-col">
          <div class="block_15 flex-row">
            <span class="text_14">找房范围</span>
            <span class="text_15">区域</span>
            <img class="thumbnail_3" referrerpolicy="no-referrer"
              src="./assets/img/pshvg0ssk8m5w44s8oe3bkrzq2k7aikvk01590bbe-5f42-4538-8259-a5b17f51d7eb.png" />
            <span class="text_16">地铁</span>
            <img class="thumbnail_4" referrerpolicy="no-referrer"
              src="./assets/img/psyrixn5s1nix1mw194377s5hs2fp3lvt15fd224f-8197-43a3-83c9-252c127d2bf4.png" />
          </div>
          <div class="image-wrapper_17 flex-row">
            <img class="image_2" referrerpolicy="no-referrer"
              src="./assets/img/psky3mdz09wqnjenjnkcyxm98n1t4k744gb029df188-ae95-4a87-95d5-633084242fe0.png" />
          </div>
          <div class="text-wrapper_54 flex-row">
            <span class="text_17">学校范围</span>
            <span class="text_18">不限</span>
            <span class="text_19">香港大学</span>
            <span class="text_20">香港中文大学</span>
            <span class="text_21">香港科技大学</span>
            <span class="text_22">香港城市大学</span>
            <span class="text_23">香港理工大学</span>
            <span class="text_24">香港浸会大学</span>
            <span class="text_25">香港都会大学</span>
            <span class="text_26">香港树仁大学</span>
          </div>
          <div class="text-wrapper_55 flex-row justify-between">
            <span class="text_27">香港岭南大学</span>
            <span class="text_28">香港教育大学</span>
            <span class="text_29">香港恒生大学</span>
          </div>
          <div class="image-wrapper_18 flex-row">
            <img class="image_3" referrerpolicy="no-referrer"
              src="./assets/img/psojum9jojjvhg3mjkl5hxtcgfqfsmikdc78e636067-61e5-4179-8bb8-6e8b90a76d96.png" />
          </div>
          <div class="block_16 flex-row">
            <span class="text_30">出租方式</span>
            <span class="text_31">不限</span>
            <div class="image-text_14 flex-row justify-between">
              <span class="text-group_1">找人合租</span>
              <img class="thumbnail_5" referrerpolicy="no-referrer"
                src="./assets/img/ps2jq8bckpe8juwrjmhjc4zmk7if0j1v29e3ec59d0-68ac-4b6d-9a0d-41c065241051.png" />
            </div>
            <div class="image-text_15 flex-row justify-between">
              <span class="text-group_2">整套出租</span>
              <img class="thumbnail_6" referrerpolicy="no-referrer"
                src="./assets/img/psvabb76khka707l4cr71tf3uil31cmjdu283905c353-f35a-43e3-bf38-4dce403b064b.png" />
            </div>
          </div>
          <div class="image-wrapper_19 flex-row">
            <img class="image_4" referrerpolicy="no-referrer"
              src="./assets/img/psqpakmsxy7ghjmj58l4uhwty6oe42sdut11663af4-112c-446c-b14e-f2d6fcb37225.png" />
          </div>
          <div class="text-wrapper_56 flex-row">
            <span class="text_32">出租租金</span>
            <span class="text_33">不限</span>
            <span class="text_34">3000港元以下</span>
            <span class="text_35">3000-4000港元</span>
            <span class="text_36">5000-6000港元</span>
            <span class="text_37">6000-7000港元</span>
            <span class="text_38">8000-9000港元</span>
            <span class="text_39">9000-10000港元</span>
            <span class="text_40">10000港元以上</span>
          </div>
          <div class="image-wrapper_20 flex-row">
            <img class="image_5" referrerpolicy="no-referrer"
              src="./assets/img/psi1vidvi25xqp79ukfz9c2ntirchu3t6b4f6d81b1-24a4-49d2-a2b4-1d11de7d68f0.png" />
          </div>
          <div class="block_17 flex-row">
            <div class="image-text_16 flex-row justify-between">
              <span class="text-group_3">收起选项</span>
              <img class="thumbnail_7" referrerpolicy="no-referrer"
                src="./assets/img/pskar5crlahndlxsrqfuhzrc1jf9huzhoce7b590cb-6692-430b-8aca-14a0a57147a6.png" />
            </div>
          </div>
        </div>
        <div class="section_3 flex-row">
          <span class="text_41">综合排序</span>
          <span class="text_42">最新发布</span>
          <span class="text_43">价格排序</span>
          <img class="thumbnail_8" referrerpolicy="no-referrer"
            src="./assets/img/psca82adz8vt79l9r32qizc4l9gd1vybmh964c0b08-250a-4f90-968d-5518725156d3.png" />
          <img class="thumbnail_9" referrerpolicy="no-referrer"
            src="./assets/img/psesum2jdjgdg0s0d2x9pe6hqzilgo2wqh7c854ff01-b9fd-468d-baac-a5343514fc88.png" />
          <span class="text_44">面积排序</span>
          <img class="thumbnail_10" referrerpolicy="no-referrer"
            src="./assets/img/psgs051q83c2e46mppfggy4tawk6mhkc4sg6dc93af5-35e7-4335-b354-c2964ef999fa.png" />
          <img class="thumbnail_11" referrerpolicy="no-referrer"
            src="./assets/img/psxc31jklzt6hkmbsmwi3j6hhivgd04nh52c7c121-3042-431b-9bc5-f1f11fcfbb31.png" />
        </div>
        <div class="block_18 flex-row justify-between">
          <div class="box_7 flex-col">
            <div class="image-wrapper_5 flex-col">
              <img class="image_6" referrerpolicy="no-referrer"
                src="./assets/img/psb7y4plywvfk58082x06ghm7n3ucyescgf27d9ca12-e80f-4e83-8269-826035778196.png" />
            </div>
            <span class="text_45">科学园/海日湾/宽敞明亮....</span>
            <div class="block_19 flex-row">
              <div class="image-text_17 flex-row justify-between">
                <img class="image_7" referrerpolicy="no-referrer"
                  src="./assets/img/pssyk4p7nxx8vs14e6tcqggkd33iuwg6fc7c9e759-1abf-4dd4-b7e7-6f719663326e.png" />
                <span class="text-group_4">大围/沙田</span>
              </div>
              <div class="section_6 flex-col"></div>
              <span class="text_46">单间</span>
              <div class="section_7 flex-col"></div>
              <span class="text_47">130平方呎</span>
            </div>
            <div class="block_20 flex-row justify-between">
              <div class="text-wrapper_6 flex-col"><span class="text_48">男女皆可</span></div>
              <div class="text-wrapper_7 flex-col"><span class="text_49">有电梯</span></div>
              <div class="text-wrapper_8 flex-col"><span class="text_50">可煮饭</span></div>
            </div>
            <div class="text-wrapper_9">
              <span class="text_51">8800</span>
              <span class="text_52">HKS/月</span>
            </div>
          </div>
          <div class="box_8 flex-col">
            <div class="image-wrapper_6 flex-col">
              <img class="image_8" referrerpolicy="no-referrer"
                src="./assets/img/ps5te5xsqm2wnccynz72tx8gyhu8sgon6cd4036e06-5ce5-4630-8a5b-a90c98d0a46d.png" />
            </div>
            <span class="text_53">科学园/海日湾/宽敞明亮....</span>
            <div class="block_21 flex-row">
              <div class="image-text_18 flex-row justify-between">
                <img class="image_9" referrerpolicy="no-referrer"
                  src="./assets/img/psezwieajd49lnleht99iigon6uu7qx03uia03b75d7-51b1-4181-a641-bde2179441c3.png" />
                <span class="text-group_5">大围/沙田</span>
              </div>
              <div class="block_2 flex-col"></div>
              <span class="text_54">单间</span>
              <div class="block_3 flex-col"></div>
              <span class="text_55">130平方呎</span>
            </div>
            <div class="block_22 flex-row justify-between">
              <div class="text-wrapper_10 flex-col"><span class="text_56">男女皆可</span></div>
              <div class="text-wrapper_11 flex-col"><span class="text_57">有电梯</span></div>
              <div class="text-wrapper_12 flex-col"><span class="text_58">可煮饭</span></div>
            </div>
            <div class="text-wrapper_13">
              <span class="text_59">8800</span>
              <span class="text_60">HKS/月</span>
            </div>
          </div>
          <div class="box_11 flex-col">
            <div class="image-wrapper_7 flex-col">
              <img class="image_10" referrerpolicy="no-referrer"
                src="./assets/img/psk461sesmbowuss90ficaq3p1yu6kxifbd5efc60c-ab70-4db9-bf38-ea3923c869e1.png" />
            </div>
            <span class="text_61">科学园/海日湾/宽敞明亮....</span>
            <div class="group_16 flex-row">
              <div class="image-text_19 flex-row justify-between">
                <img class="image_11" referrerpolicy="no-referrer"
                  src="./assets/img/ps85xxhbd4lc6ex3a0qgc1yz4d8d3trdrq1841533d-6de9-47d2-b2e3-a2c81439124d.png" />
                <span class="text-group_6">大围/沙田</span>
              </div>
              <div class="group_3 flex-col"></div>
              <span class="text_62">单间</span>
              <div class="group_4 flex-col"></div>
              <span class="text_63">130平方呎</span>
            </div>
            <div class="group_17 flex-row justify-between">
              <div class="text-wrapper_14 flex-col"><span class="text_64">男女皆可</span></div>
              <div class="text-wrapper_15 flex-col"><span class="text_65">有电梯</span></div>
              <div class="text-wrapper_16 flex-col"><span class="text_66">可煮饭</span></div>
            </div>
            <div class="text-wrapper_17">
              <span class="text_67">8800</span>
              <span class="text_68">HKS/月</span>
            </div>
          </div>
        </div>
        <div class="block_23 flex-row justify-between">
          <div class="box_12 flex-col">
            <div class="image-wrapper_8 flex-col">
              <img class="image_12" referrerpolicy="no-referrer"
                src="./assets/img/psrpzy4o9or5on9fj766a8zsguha02u307l0d5bde51-bca7-4332-a9e3-4a3341ef9d1e.png" />
            </div>
            <span class="text_69">科学园/海日湾/宽敞明亮....</span>
            <div class="group_18 flex-row">
              <div class="image-text_20 flex-row justify-between">
                <img class="image_13" referrerpolicy="no-referrer"
                  src="./assets/img/psulwd5gcbhtyxegvc1yt8mm7lsi5vnnun010436a1-8a3e-45cc-b25e-31dbc1992dd7.png" />
                <span class="text-group_7">大围/沙田</span>
              </div>
              <div class="box_13 flex-col"></div>
              <span class="text_70">单间</span>
              <div class="box_14 flex-col"></div>
              <span class="text_71">130平方呎</span>
            </div>
            <div class="group_19 flex-row justify-between">
              <div class="text-wrapper_18 flex-col"><span class="text_72">男女皆可</span></div>
              <div class="text-wrapper_19 flex-col"><span class="text_73">有电梯</span></div>
              <div class="text-wrapper_20 flex-col"><span class="text_74">可煮饭</span></div>
            </div>
            <div class="text-wrapper_21">
              <span class="text_75">8800</span>
              <span class="text_76">HKS/月</span>
            </div>
          </div>
          <div class="box_15 flex-col">
            <div class="image-wrapper_9 flex-col">
              <img class="image_14" referrerpolicy="no-referrer"
                src="./assets/img/psoxv29nuusyk2yp0a5rgrijsvz02ndk3kg8aa97ea5-8373-4847-a093-f4493390d27f.png" />
            </div>
            <span class="text_77">科学园/海日湾/宽敞明亮....</span>
            <div class="block_24 flex-row">
              <div class="image-text_21 flex-row justify-between">
                <img class="image_15" referrerpolicy="no-referrer"
                  src="./assets/img/psdgkr3xuikiuxoaqzi6at8g6f8kr8xi93ab44795-cd05-4bd7-8b7c-b96b583ba6d4.png" />
                <span class="text-group_8">大围/沙田</span>
              </div>
              <div class="block_4 flex-col"></div>
              <span class="text_78">单间</span>
              <div class="block_5 flex-col"></div>
              <span class="text_79">130平方呎</span>
            </div>
            <div class="block_25 flex-row justify-between">
              <div class="text-wrapper_22 flex-col"><span class="text_80">男女皆可</span></div>
              <div class="text-wrapper_23 flex-col"><span class="text_81">有电梯</span></div>
              <div class="text-wrapper_24 flex-col"><span class="text_82">可煮饭</span></div>
            </div>
            <div class="text-wrapper_25">
              <span class="text_83">8800</span>
              <span class="text_84">HKS/月</span>
            </div>
          </div>
          <div class="box_16 flex-col">
            <div class="image-wrapper_10 flex-col">
              <img class="image_16" referrerpolicy="no-referrer"
                src="./assets/img/psfrfrwnpnmleg8mmtexq0t5pgcxb2h6er57fbe65a-ceea-44bc-bbdb-96d1f65308d6.png" />
            </div>
            <span class="text_85">科学园/海日湾/宽敞明亮....</span>
            <div class="box_25 flex-row">
              <div class="image-text_22 flex-row justify-between">
                <img class="image_17" referrerpolicy="no-referrer"
                  src="./assets/img/ps4qi8j7ibcufgiogoju9rzvqkfdkc5a50ba4fbadf0-0341-410f-87d0-91436e37ce50.png" />
                <span class="text-group_9">大围/沙田</span>
              </div>
              <div class="group_9 flex-col"></div>
              <span class="text_86">单间</span>
              <div class="group_10 flex-col"></div>
              <span class="text_87">130平方呎</span>
            </div>
            <div class="box_26 flex-row justify-between">
              <div class="text-wrapper_26 flex-col"><span class="text_88">男女皆可</span></div>
              <div class="text-wrapper_27 flex-col"><span class="text_89">有电梯</span></div>
              <div class="text-wrapper_28 flex-col"><span class="text_90">可煮饭</span></div>
            </div>
            <div class="text-wrapper_29">
              <span class="text_91">8800</span>
              <span class="text_92">HKS/月</span>
            </div>
          </div>
        </div>
        <div class="block_26 flex-row justify-between">
          <div class="block_6 flex-col">
            <div class="image-wrapper_11 flex-col">
              <img class="image_18" referrerpolicy="no-referrer"
                src="./assets/img/psj5s7sex834if7kj5l17unk1707zt24eeie9a68c9a-ee8e-423b-9108-10650fc2a404.png" />
            </div>
            <span class="text_93">科学园/海日湾/宽敞明亮....</span>
            <div class="box_27 flex-row">
              <div class="image-text_23 flex-row justify-between">
                <img class="image_19" referrerpolicy="no-referrer"
                  src="./assets/img/pshzxoggpi155ntkehzfvvm8vmjhzzqgglmbcb3a6e1-7c87-4887-9e92-dd1f40ae0d6b.png" />
                <span class="text-group_10">大围/沙田</span>
              </div>
              <div class="box_18 flex-col"></div>
              <span class="text_94">单间</span>
              <div class="box_19 flex-col"></div>
              <span class="text_95">130平方呎</span>
            </div>
            <div class="box_28 flex-row justify-between">
              <div class="text-wrapper_30 flex-col"><span class="text_96">男女皆可</span></div>
              <div class="text-wrapper_31 flex-col"><span class="text_97">有电梯</span></div>
              <div class="text-wrapper_32 flex-col"><span class="text_98">可煮饭</span></div>
            </div>
            <div class="text-wrapper_33">
              <span class="text_99">8800</span>
              <span class="text_100">HKS/月</span>
            </div>
          </div>
          <div class="block_7 flex-col">
            <div class="image-wrapper_12 flex-col">
              <img class="image_20" referrerpolicy="no-referrer"
                src="./assets/img/pshplklue21yw4em0ggnuepzh16zphiqb413e11e83-7d68-425e-ae18-587cda619e2b.png" />
            </div>
            <span class="text_101">科学园/海日湾/宽敞明亮....</span>
            <div class="box_29 flex-row">
              <div class="image-text_24 flex-row justify-between">
                <img class="image_21" referrerpolicy="no-referrer"
                  src="./assets/img/pstid5wwg4wtwbs3i26a7lscsph67kthzc085716d1-3f95-48b6-9ff0-42c4227ce891.png" />
                <span class="text-group_11">大围/沙田</span>
              </div>
              <div class="block_9 flex-col"></div>
              <span class="text_102">单间</span>
              <div class="block_10 flex-col"></div>
              <span class="text_103">130平方呎</span>
            </div>
            <div class="box_30 flex-row justify-between">
              <div class="text-wrapper_34 flex-col"><span class="text_104">男女皆可</span></div>
              <div class="text-wrapper_35 flex-col"><span class="text_105">有电梯</span></div>
              <div class="text-wrapper_36 flex-col"><span class="text_106">可煮饭</span></div>
            </div>
            <div class="text-wrapper_37">
              <span class="text_107">8800</span>
              <span class="text_108">HKS/月</span>
            </div>
          </div>
          <div class="block_12 flex-col">
            <div class="image-wrapper_13 flex-col">
              <img class="image_22" referrerpolicy="no-referrer"
                src="./assets/img/psf7y5qo5ndqe2a5i1t6ft8gxhvstd4zvqbde13ef92-cc37-4e1f-99f7-cc873c659842.png" />
            </div>
            <span class="text_109">科学园/海日湾/宽敞明亮....</span>
            <div class="section_18 flex-row">
              <div class="image-text_25 flex-row justify-between">
                <img class="image_23" referrerpolicy="no-referrer"
                  src="./assets/img/ps2vsyrmh7zdh7emky96lfoa72g756z1juj170a0010-9943-44b2-a681-38110c488319.png" />
                <span class="text-group_12">大围/沙田</span>
              </div>
              <div class="section_13 flex-col"></div>
              <span class="text_110">单间</span>
              <div class="section_14 flex-col"></div>
              <span class="text_111">130平方呎</span>
            </div>
            <div class="section_19 flex-row justify-between">
              <div class="text-wrapper_38 flex-col"><span class="text_112">男女皆可</span></div>
              <div class="text-wrapper_39 flex-col"><span class="text_113">有电梯</span></div>
              <div class="text-wrapper_40 flex-col"><span class="text_114">可煮饭</span></div>
            </div>
            <div class="text-wrapper_41">
              <span class="text_115">8800</span>
              <span class="text_116">HKS/月</span>
            </div>
          </div>
        </div>
        <div class="block_27 flex-row">
          <div class="image-wrapper_14 flex-col">
            <img class="thumbnail_12" referrerpolicy="no-referrer"
              src="./assets/img/psgi6289pnexhv737m2mucsyi24percej185ed870-3e87-484a-9da3-fd6f7aa033c9.png" />
          </div>
          <div class="text-wrapper_42 flex-col"><span class="text_117">1</span></div>
          <div class="text-wrapper_43 flex-col"><span class="text_118">2</span></div>
          <div class="text-wrapper_44 flex-col"><span class="text_119">3</span></div>
          <div class="text-wrapper_45 flex-col"><span class="text_120">4</span></div>
          <div class="text-wrapper_46 flex-col"><span class="text_121">5</span></div>
          <div class="image-wrapper_15 flex-col">
            <img class="thumbnail_13" referrerpolicy="no-referrer"
              src="./assets/img/psz1ev02ilbwdag95cna3h2lt276jtswk9d37a16c0-8afd-417e-a95c-260659fe0b10.png" />
          </div>
          <span class="text_122">共</span>
          <span class="text_123">9</span>
          <span class="text_124">页，</span>
          <span class="text_125">到第</span>
          <div class="box_21 flex-col"></div>
          <span class="text_126">页</span>
          <div class="text-wrapper_47 flex-col"><span class="text_127">确定</span></div>
        </div>
      </div>
      <div class="box_22 flex-col">
        <div class="section_20 flex-row">
          <div class="box_23 flex-row">
            <div class="image-text_26 flex-row justify-between">
              <img class="label_3" referrerpolicy="no-referrer"
                src="./assets/img/psdwrxrqvkplvaal99h8q3wd8ezgil5lsbq2ab7666f-b7c9-4ebe-8603-e7ca6304fc23.png" />
              <span class="text-group_13">简体中文</span>
            </div>
            <img class="thumbnail_14" referrerpolicy="no-referrer"
              src="./assets/img/psfglvyrca4l9zqze3z99uho8bt5pfk1mocc7450da6-912c-48f8-b490-988496474509.png" />
          </div>
          <span class="text_128">港专租</span>
          <span class="text_129">合作咨询</span>
          <span class="text_130">其他</span>
          <span class="text_131">订阅邮件咨询</span>
        </div>
        <div class="section_21 flex-row">
          <div class="text-wrapper_57 flex-col justify-between">
            <span class="text_132">关于我们</span>
            <span class="text_133">官方微信</span>
          </div>
          <div class="text-wrapper_58 flex-col justify-between">
            <span class="text_134">成为合作伙伴</span>
            <span class="text_135">房源合作</span>
          </div>
          <div class="text-wrapper_59 flex-col justify-between">
            <span class="text_136">常见问题</span>
            <span class="text_137">加入我们</span>
          </div>
          <div class="block_13 flex-row">
            <span class="text_138">输入邮件地址</span>
            <div class="section_16 flex-col">
              <div class="image-wrapper_16 flex-col">
                <img class="image_24" referrerpolicy="no-referrer"
                  src="./assets/img/ps2lhwsvz79jm9ofitnhat1jzd7bfjgcdy0ffcd78a-a3b1-4f2b-af8a-c977e0835c27.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="text-wrapper_60 flex-row"><span class="text_139">虚位以待</span></div>
        <div class="text-wrapper_61 flex-row"><span class="text_140">Copy&nbsp;Right©中国香港港专租有限公司</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />