
<template>
    <div>
        <Top_head></Top_head>

        <div class="distribution">
            <div class="g_cw g_mt g_mb">
                <div class="g_title">
                    <h5>最新活动</h5>
                    <h6>EVENT</h6>
                </div>
                <div class="xq">
                    <ul class="list">

                        <li v-for="item in activitys">
                            <a class="box">
                                <div class="di_a">
                                    <img class="ct_a" :src="item.showPic" />
                                </div>
                                <div class="di_b">
                                    <h6 class="l1">{{ item.title }}</h6>
                                </div>
                                <div class="di_c">
                                    <p class="l2">活动时间：<br>{{ item.beginDate }}--{{ item.endDate }}</p>
                                    <p class="l2">活动简介：{{ item.remark }}</p>
                                    <el-link type="primary" @click="click_detail(item.id)">活动详情</el-link>
                                </div>
                            </a>
                        </li>



                    </ul>
                    <div class="pages">
                        <el-pagination @current-change="paging" :current-page="currentpage" :page-size="9" background
                            layout="total, prev, pager, next" :total="totalRecord">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <Bottom></Bottom>

    </div>
</template>
<script>
import * as api from "../../api/api"
import Top_head from '@/components/Top_head.vue';
import Bottom from "@/components/Bottom.vue";
export default {
    components: { Top_head, Bottom },
    created: async function () {


        this.get_data()

    },
    filters: {
        ellipsis(value) {
            if (!value) return '';
            if (value.length > 50) {
                return value.slice(0, 50) + '...'
            }
            return value
        }
    },
    data() {
        return {
            totalRecord: 0,
            currentpage: 1,
            pagesize: 9,

            activitys: [],

        };
    },

    methods: {
        click_menu(topage) {
            this.$router.push(topage);
        },
        click_detail(id) {
            this.$router.push('/eventDetail?id=' + id);
        },
        get_data() {
            var params = {
                topage: this.currentpage,
                pagesize: this.pagesize,
            }
            api.getRequest('activitys',
                params).then(resp => {
                    // console.log(resp)

                    this.activitys = resp.data.records
                    this.totalRecord = resp.data.total
                    // console.log(this.totalRecord)

                })
        },
        paging(val) {
            // console.log(val)
            this.currentpage = val;
            this.get_data();
        },



    }
}
</script>
<style scoped lang="css" src="./assets/index.css" />
