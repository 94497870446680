<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="group_1 flex-row">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/psllvjjvfmnelhsj8kf0r7d1in426ew48p4429744e-25e2-41d4-bb33-54c4eb5c868a.png"
        />
        <span class="text_207">首页</span>
        <span class="text_208">房东房源</span>
        <span class="text_209">公寓房源</span>
        <span class="text_210">酒店房源</span>
        <span class="text_211">服务</span>
        <span class="text_212">活动</span>
        <span class="text_213">最新消息</span>
        <span class="text_214">迷一样住客</span>
        <div class="image-wrapper_31 flex-col align-center">
          <img
            class="label_4"
            referrerpolicy="no-referrer"
            src="./assets/img/psmr9dhotdhu9rp8rsnsxdk58kiu5uaru63466c01a-a7fc-41e6-a19e-856770bb37d1.png"
          />
        </div>
        <div class="image-wrapper_32 flex-col align-center">
          <img
            class="thumbnail_23"
            referrerpolicy="no-referrer"
            src="./assets/img/psfeus1f53c7bsbj8vxkw6rfo6j01hk3rg2a738e7c-bf0a-4c20-acad-42c10b8381e5.png"
          />
        </div>
      </div>
      <div class="section_15 flex-col">
        <div class="text-wrapper_1">
          <span class="text_9">首页&nbsp;/</span>
          <span class="text_10">房东房源</span>
        </div>
        <div class="section_1 flex-row justify-end">
          <span class="text_11">欢迎来到香港，您想租住哪里呢？</span>
          <div class="group_3 flex-col"></div>
          <img
            class="thumbnail_2"
            referrerpolicy="no-referrer"
            src="./assets/img/psdiek1enbc5ivly18ercminwid6fm5jbxd2459edd8-6eae-4628-a11c-1ad946147742.png"
          />
          <span class="text_12">地图房源</span>
          <div class="text-wrapper_2 flex-col"><span class="text_13">立即查询</span></div>
          <img
            class="label_2"
            referrerpolicy="no-referrer"
            src="./assets/img/ps9wylq6l3h1t4exhghhxidrshj0ltqwdbm1483974e-e41b-4880-98ee-ed24d1bc196b.png"
          />
        </div>
        <div class="section_2 flex-col">
          <div class="group_44 flex-row">
            <span class="text_14">找房范围</span>
            <span class="text_15">区域</span>
            <img
              class="thumbnail_3"
              referrerpolicy="no-referrer"
              src="./assets/img/psr0ajzun4blev0zvj3c6o898u6i1y16e67d2b437-cf97-44a4-b935-5c4ed15d23bb.png"
            />
            <span class="text_16">地铁</span>
            <img
              class="thumbnail_4"
              referrerpolicy="no-referrer"
              src="./assets/img/pszd18gjqcj66n6xyfk7gbdusejpctog5r511c612e-deac-4824-bac1-6bf1f0c5ee76.png"
            />
          </div>
          <div class="text-wrapper_78 flex-row">
            <span class="text_17">不限</span>
            <span class="text_18">中西区</span>
            <span class="text_19">东区</span>
            <span class="text_20">南区</span>
            <span class="text_21">北区</span>
            <span class="text_22">湾子区</span>
            <span class="text_23">九龙城区</span>
            <span class="text_24">观塘区</span>
            <span class="text_25">油尖旺区</span>
            <span class="text_26">深水埗区</span>
            <span class="text_27">黄大仙区</span>
            <span class="text_28">荃湾区</span>
            <span class="text_29">屯门区</span>
            <span class="text_30">元朗区</span>
          </div>
          <div class="text-wrapper_79 flex-row justify-between">
            <span class="text_31">大埔区</span>
            <span class="text_32">西贡区</span>
            <span class="text_33">沙田区</span>
            <span class="text_34">葵青区</span>
            <span class="text_35">离岛区</span>
          </div>
          <div class="group_45 flex-row">
            <div class="group_4 flex-col"></div>
            <span class="text_36">全部</span>
            <div class="group_5 flex-col"></div>
            <span class="text_37">金钟</span>
            <div class="group_6 flex-col"></div>
            <span class="text_38">中环</span>
            <div class="group_7 flex-col"></div>
            <span class="text_39">上环</span>
            <div class="group_8 flex-col"></div>
            <span class="text_40">西营盘</span>
            <div class="group_9 flex-col"></div>
            <span class="text_41">石塘嘴</span>
            <div class="group_10 flex-col"></div>
            <span class="text_42">坚尼地域</span>
          </div>
          <div class="image-wrapper_33 flex-row">
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/ps3az66imwsk83ul4gexwh045z2assn18ur46fc395d-06e5-4d6b-9283-d961984007d5.png"
            />
          </div>
          <div class="text-wrapper_80 flex-row">
            <span class="text_43">学校范围</span>
            <span class="text_44">不限</span>
            <span class="text_45">香港大学</span>
            <span class="text_46">香港中文大学</span>
            <span class="text_47">香港科技大学</span>
            <span class="text_48">香港城市大学</span>
            <span class="text_49">香港理工大学</span>
            <span class="text_50">香港浸会大学</span>
            <span class="text_51">香港都会大学</span>
            <span class="text_52">香港树仁大学</span>
          </div>
          <div class="text-wrapper_81 flex-row justify-between">
            <span class="text_53">香港岭南大学</span>
            <span class="text_54">香港教育大学</span>
            <span class="text_55">香港恒生大学</span>
          </div>
          <div class="image-wrapper_34 flex-row">
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="./assets/img/pscju0uibae9dkmn972jjsph3yb3swtfmq152f9fe8-5d64-442e-9dcd-663cdefcc33d.png"
            />
          </div>
          <div class="group_46 flex-row">
            <span class="text_56">出租方式</span>
            <span class="text_57">不限</span>
            <div class="image-text_43 flex-row justify-between">
              <span class="text-group_1">找人合租</span>
              <img
                class="thumbnail_5"
                referrerpolicy="no-referrer"
                src="./assets/img/ps6rzufjezpuf3eegqrtl7wdergijp0yvov139980ea-0f65-4bc9-89bb-a7ab804047e6.png"
              />
            </div>
            <div class="image-text_44 flex-row justify-between">
              <span class="text-group_2">整套出租</span>
              <img
                class="thumbnail_6"
                referrerpolicy="no-referrer"
                src="./assets/img/psrc1pwll668j2xyxqpmllszl6qx0ei84gm253a229e-fb0f-4a24-96b3-35313054fbcf.png"
              />
            </div>
          </div>
          <div class="text-wrapper_82 flex-row">
            <span class="text_58">全部</span>
            <span class="text_59">房间</span>
            <span class="text_60">床位</span>
            <span class="text_61">客厅</span>
            <span class="text_62">其他</span>
          </div>
          <div class="image-wrapper_35 flex-row">
            <img
              class="image_4"
              referrerpolicy="no-referrer"
              src="./assets/img/psq1av0hmpdoapohs6uhmaigz6c89f082bjf6c006c8-871a-4a91-a0ab-5f1a60919efe.png"
            />
          </div>
          <div class="text-wrapper_83 flex-row">
            <span class="text_63">出租租金</span>
            <span class="text_64">不限</span>
            <span class="text_65">3000港元以下</span>
            <span class="text_66">3000-4000港元</span>
            <span class="text_67">5000-6000港元</span>
            <span class="text_68">6000-7000港元</span>
            <span class="text_69">8000-9000港元</span>
            <span class="text_70">9000-10000港元</span>
            <span class="text_71">10000港元以上</span>
          </div>
          <div class="image-wrapper_36 flex-row">
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="./assets/img/psfu4n7vzwnvf07iiwb8pb8zialx3wohrgz4573a0f61-7d78-4aa0-a594-7a2e8da11a69.png"
            />
          </div>
          <div class="text-wrapper_84 flex-row">
            <span class="text_72">租期</span>
            <span class="text_73">不限</span>
            <span class="text_74">低于一年</span>
            <span class="text_75">一年以上</span>
          </div>
          <div class="image-wrapper_37 flex-row">
            <img
              class="image_6"
              referrerpolicy="no-referrer"
              src="./assets/img/ps5qoq70ziphkxamdx432ucn1la9b4gbmsd6ce3684-c59f-473d-8848-00f88d7cf02a.png"
            />
          </div>
          <div class="text-wrapper_85 flex-row">
            <span class="text_76">性别</span>
            <span class="text_77">不限</span>
            <span class="text_78">男</span>
            <span class="text_79">女</span>
          </div>
          <div class="image-wrapper_38 flex-row">
            <img
              class="image_7"
              referrerpolicy="no-referrer"
              src="./assets/img/ps7wfs9rvmzn8aa3j5au2l86n1snsqar0fdb930643-e1c4-4c63-aa88-c4fb92017400.png"
            />
          </div>
          <div class="text-wrapper_86 flex-row">
            <span class="text_80">发布时间</span>
            <span class="text_81">不限</span>
            <span class="text_82">三天</span>
            <span class="text_83">一周</span>
            <span class="text_84">一个月内</span>
            <span class="text_85">二个月内</span>
            <span class="text_86">三个月内</span>
          </div>
          <div class="image-wrapper_39 flex-row">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/ps52ssf3c4dkgtg2artjf3favopw3fbd9043f6f2-ab41-4c0e-bf26-24ede4c47f8d.png"
            />
          </div>
          <div class="text-wrapper_87 flex-row">
            <span class="text_87">面积区间</span>
            <span class="text_88">50呎以下</span>
            <span class="text_89">50-100呎</span>
            <span class="text_90">100-200呎</span>
            <span class="text_91">200呎以上</span>
          </div>
          <div class="image-wrapper_40 flex-row">
            <img
              class="image_9"
              referrerpolicy="no-referrer"
              src="./assets/img/pscn5hr6l3hyooyimaw1z765vrd3mvcq7kcc7b92ff-7fce-454d-a96e-3bd7ef0ff361.png"
            />
          </div>
          <div class="text-wrapper_88 flex-row">
            <span class="text_92">特色户型</span>
            <span class="text_93">Loft</span>
            <span class="text_94">近地铁</span>
            <span class="text_95">有电梯</span>
            <span class="text_96">近学校</span>
            <span class="text_97">独立阳台</span>
            <span class="text_98">独立卫生间</span>
            <span class="text_99">健身房</span>
            <span class="text_100">可养宠物</span>
            <span class="text_101">工作空间</span>
            <span class="text_102">洗衣服务</span>
          </div>
          <div class="image-wrapper_41 flex-row">
            <img
              class="image_10"
              referrerpolicy="no-referrer"
              src="./assets/img/psuvpdhkrx5se4hbqwjjkcha0zwc4fun2ffpcf2e79e9-ba76-4294-8dd5-5686b249d924.png"
            />
          </div>
          <div class="group_47 flex-row">
            <div class="image-text_45 flex-row justify-between">
              <span class="text-group_3">收起选项</span>
              <img
                class="thumbnail_7"
                referrerpolicy="no-referrer"
                src="./assets/img/ps4bjdh9rebfjqx5yb85ddwmdqabgkoppbef95b2a93-dfd6-4ee3-a2e9-b382bf4cb63f.png"
              />
            </div>
          </div>
        </div>
        <div class="section_3 flex-col">
          <div class="box_44 flex-row">
            <span class="text_103">您已选择</span>
            <div class="box_2 flex-row">
              <div class="image-text_46 flex-row justify-between">
                <span class="text-group_4">中西区</span>
                <img
                  class="thumbnail_8"
                  referrerpolicy="no-referrer"
                  src="./assets/img/pse0m0h6ygzgrlb3umwmwdvf0ajjlifgtn9c3285b07e-c299-4b2a-a39c-97f2dfa3bcc5.png"
                />
              </div>
            </div>
            <div class="box_3 flex-row">
              <div class="image-text_47 flex-row justify-between">
                <span class="text-group_5">香港大学</span>
                <img
                  class="thumbnail_9"
                  referrerpolicy="no-referrer"
                  src="./assets/img/ps47bin8oddpef42rpoylb2q0zc8i1loidzf653c1a4-eb62-4a31-94cf-c5932095a177.png"
                />
              </div>
            </div>
            <div class="box_4 flex-row">
              <div class="image-text_48 flex-row justify-between">
                <span class="text-group_6">找人合租</span>
                <img
                  class="thumbnail_10"
                  referrerpolicy="no-referrer"
                  src="./assets/img/psid7v3q867tkpms472yqlthqbjaf62fsc28a31716-fefd-42a7-a544-caa209c04860.png"
                />
              </div>
            </div>
            <div class="box_5 flex-row">
              <div class="image-text_49 flex-row justify-between">
                <span class="text-group_7">3000港元以下</span>
                <img
                  class="thumbnail_11"
                  referrerpolicy="no-referrer"
                  src="./assets/img/ps5ufmour3tr83mqdby9s2lebj0lf05ns3e0f614f8d-cd43-48e3-966f-b27040b54f6e.png"
                />
              </div>
            </div>
            <div class="box_6 flex-row">
              <div class="image-text_50 flex-row justify-between">
                <span class="text-group_8">低于一年</span>
                <img
                  class="thumbnail_12"
                  referrerpolicy="no-referrer"
                  src="./assets/img/pshajxv7b3hhk4kstgz02dfrrdxme5aukxjcb812807-90e6-4588-9f3e-e7dfa89d1e4b.png"
                />
              </div>
            </div>
            <div class="box_7 flex-row">
              <div class="image-text_51 flex-row justify-between">
                <span class="text-group_9">男</span>
                <img
                  class="thumbnail_13"
                  referrerpolicy="no-referrer"
                  src="./assets/img/psmoyykjb1ttozwsxy1bm9bs6o2iau9tbg59a31f35-9488-40ac-abdf-892aae237a86.png"
                />
              </div>
            </div>
            <div class="box_8 flex-row">
              <div class="image-text_52 flex-row justify-between">
                <span class="text-group_10">三天</span>
                <img
                  class="thumbnail_14"
                  referrerpolicy="no-referrer"
                  src="./assets/img/ps23l4cu4djjw0ygiz6ekttrf5stkrqqfqe5d5bfa9-0bea-41e2-a4c3-5758c3d0cd07.png"
                />
              </div>
            </div>
            <div class="image-text_53 flex-row justify-between">
              <img
                class="thumbnail_15"
                referrerpolicy="no-referrer"
                src="./assets/img/psintsxee4lfxcqm6ukjhik7zt8ysrvrsp55fb722b-e53a-44a8-b24b-112ff5916952.png"
              />
              <span class="text-group_11">清空条件</span>
            </div>
          </div>
          <div class="box_45 flex-row">
            <div class="text-wrapper_14">
              <span class="text_104">已为您找到符合条件的</span>
              <span class="text_105">&nbsp;58套</span>
              <span class="text_106">房源</span>
            </div>
            <span class="text_107">综合排序</span>
            <span class="text_108">最新发布</span>
            <span class="text_109">价格排序</span>
            <img
              class="thumbnail_16"
              referrerpolicy="no-referrer"
              src="./assets/img/psfveaqi6c7zld6h2gealri9jppqrtbm9e30c8a84f-0539-415b-8a5f-ab32a8eddcdc.png"
            />
            <img
              class="thumbnail_17"
              referrerpolicy="no-referrer"
              src="./assets/img/psava4trgn4isanv5o961qjip3sp7xkx6n5d0332d9-6b53-4a4f-9047-03c37dc06f76.png"
            />
            <span class="text_110">面积排序</span>
            <img
              class="thumbnail_18"
              referrerpolicy="no-referrer"
              src="./assets/img/psl35rikobrs3kne2iwnpv7dmch9253fql677b9629-1085-4544-9fc4-cbb23d862e85.png"
            />
            <img
              class="thumbnail_19"
              referrerpolicy="no-referrer"
              src="./assets/img/ps3xjcohdos16e7g3c5b6cmjeaba16656fk429501b8-dd0d-4049-aec5-f63f2e09f93b.png"
            />
          </div>
        </div>
        <div class="box_46 flex-row justify-between">
          <div class="box_9 flex-col">
            <div class="image-wrapper_10 flex-col">
              <img
                class="image_11"
                referrerpolicy="no-referrer"
                src="./assets/img/psk1c3q4640cjxjgzu9vh4u5z4ykw10una48d06e7-125f-47ce-bf71-3f8a5f43ddac.png"
              />
            </div>
            <span class="text_111">科学园/海日湾/宽敞明亮....</span>
            <div class="group_48 flex-row">
              <div class="image-text_54 flex-row justify-between">
                <img
                  class="image_12"
                  referrerpolicy="no-referrer"
                  src="./assets/img/ps62ipm5rt4z8m5lgfjy5s3rzzoxgtb0oa26a904e-291f-4ee5-b815-e374f00dfd0d.png"
                />
                <span class="text-group_12">大围/沙田</span>
              </div>
              <div class="box_11 flex-col"></div>
              <span class="text_112">单间</span>
              <div class="box_12 flex-col"></div>
              <span class="text_113">130平方呎</span>
            </div>
            <div class="group_49 flex-row justify-between">
              <div class="text-wrapper_15 flex-col"><span class="text_114">男女皆可</span></div>
              <div class="text-wrapper_16 flex-col"><span class="text_115">有电梯</span></div>
              <div class="text-wrapper_17 flex-col"><span class="text_116">可煮饭</span></div>
            </div>
            <div class="text-wrapper_18">
              <span class="text_117">8800</span>
              <span class="text_118">HKS/月</span>
            </div>
          </div>
          <div class="box_14 flex-col">
            <div class="image-wrapper_11 flex-col">
              <img
                class="image_13"
                referrerpolicy="no-referrer"
                src="./assets/img/ps9nmb6rbq2on6fs5wi2f6v2j53xv88vwboa993d7d4-904f-4176-9ce9-f1364176ffe7.png"
              />
            </div>
            <span class="text_119">科学园/海日湾/宽敞明亮....</span>
            <div class="box_47 flex-row">
              <div class="image-text_55 flex-row justify-between">
                <img
                  class="image_14"
                  referrerpolicy="no-referrer"
                  src="./assets/img/ps9re21ytrq9jwkr1axddjmlqnd6tlf4k90559868-7ec8-4c1f-8d9c-0bad73a0bcc5.png"
                />
                <span class="text-group_13">大围/沙田</span>
              </div>
              <div class="section_5 flex-col"></div>
              <span class="text_120">单间</span>
              <div class="section_6 flex-col"></div>
              <span class="text_121">130平方呎</span>
            </div>
            <div class="box_48 flex-row justify-between">
              <div class="text-wrapper_19 flex-col"><span class="text_122">男女皆可</span></div>
              <div class="text-wrapper_20 flex-col"><span class="text_123">有电梯</span></div>
              <div class="text-wrapper_21 flex-col"><span class="text_124">可煮饭</span></div>
            </div>
            <div class="text-wrapper_22">
              <span class="text_125">8800</span>
              <span class="text_126">HKS/月</span>
            </div>
          </div>
          <div class="box_15 flex-col">
            <div class="image-wrapper_12 flex-col">
              <img
                class="image_15"
                referrerpolicy="no-referrer"
                src="./assets/img/psm4izs3wef4pv8fo5quf3f5ajucntrpwead1bf49-a7c6-4d0a-be9e-4836bbea7a1b.png"
              />
            </div>
            <span class="text_127">科学园/海日湾/宽敞明亮....</span>
            <div class="group_50 flex-row">
              <div class="image-text_56 flex-row justify-between">
                <img
                  class="image_16"
                  referrerpolicy="no-referrer"
                  src="./assets/img/pszo6iwx47j6fufw983wvxens17h8rvwbn35573a06-615a-450f-8e33-fc9fb73d20a4.png"
                />
                <span class="text-group_14">大围/沙田</span>
              </div>
              <div class="group_15 flex-col"></div>
              <span class="text_128">单间</span>
              <div class="group_16 flex-col"></div>
              <span class="text_129">130平方呎</span>
            </div>
            <div class="group_51 flex-row justify-between">
              <div class="text-wrapper_23 flex-col"><span class="text_130">男女皆可</span></div>
              <div class="text-wrapper_24 flex-col"><span class="text_131">有电梯</span></div>
              <div class="text-wrapper_25 flex-col"><span class="text_132">可煮饭</span></div>
            </div>
            <div class="text-wrapper_26">
              <span class="text_133">8800</span>
              <span class="text_134">HKS/月</span>
            </div>
          </div>
        </div>
        <div class="box_49 flex-row justify-between">
          <div class="block_6 flex-col">
            <div class="image-wrapper_13 flex-col">
              <img
                class="image_17"
                referrerpolicy="no-referrer"
                src="./assets/img/psjs5ax1l4eqlbx98t21468crjsnxq51zineaa19a3b-7e19-4653-b815-e538596b47bb.png"
              />
            </div>
            <span class="text_135">科学园/海日湾/宽敞明亮....</span>
            <div class="box_50 flex-row">
              <div class="image-text_57 flex-row justify-between">
                <img
                  class="image_18"
                  referrerpolicy="no-referrer"
                  src="./assets/img/psb3tcbw8dxss5z5e9dos5ago9jcr8nzbu525126fa-2b8f-44a6-89d5-c9bd23b0583b.png"
                />
                <span class="text-group_15">大围/沙田</span>
              </div>
              <div class="section_8 flex-col"></div>
              <span class="text_136">单间</span>
              <div class="section_9 flex-col"></div>
              <span class="text_137">130平方呎</span>
            </div>
            <div class="box_51 flex-row justify-between">
              <div class="text-wrapper_27 flex-col"><span class="text_138">男女皆可</span></div>
              <div class="text-wrapper_28 flex-col"><span class="text_139">有电梯</span></div>
              <div class="text-wrapper_29 flex-col"><span class="text_140">可煮饭</span></div>
            </div>
            <div class="text-wrapper_30">
              <span class="text_141">8800</span>
              <span class="text_142">HKS/月</span>
            </div>
          </div>
          <div class="block_7 flex-col">
            <div class="image-wrapper_14 flex-col">
              <img
                class="image_19"
                referrerpolicy="no-referrer"
                src="./assets/img/psofzo4ervues1nqfjfiyvqru272lnnt246cb5cb5-0034-4a8e-bb3b-1df8b898ed07.png"
              />
            </div>
            <span class="text_143">科学园/海日湾/宽敞明亮....</span>
            <div class="group_52 flex-row">
              <div class="image-text_58 flex-row justify-between">
                <img
                  class="image_20"
                  referrerpolicy="no-referrer"
                  src="./assets/img/psisdtihouv5b68jc6ozuuchs34vha26co389a1e22-0f7f-4cbc-9ce4-76b9e225910b.png"
                />
                <span class="text-group_16">大围/沙田</span>
              </div>
              <div class="box_20 flex-col"></div>
              <span class="text_144">单间</span>
              <div class="box_21 flex-col"></div>
              <span class="text_145">130平方呎</span>
            </div>
            <div class="group_53 flex-row justify-between">
              <div class="text-wrapper_31 flex-col"><span class="text_146">男女皆可</span></div>
              <div class="text-wrapper_32 flex-col"><span class="text_147">有电梯</span></div>
              <div class="text-wrapper_33 flex-col"><span class="text_148">可煮饭</span></div>
            </div>
            <div class="text-wrapper_34">
              <span class="text_149">8800</span>
              <span class="text_150">HKS/月</span>
            </div>
          </div>
          <div class="block_8 flex-col">
            <div class="image-wrapper_15 flex-col">
              <img
                class="image_21"
                referrerpolicy="no-referrer"
                src="./assets/img/psbrvesvugspmjocw11ivivs5hhsp4x4nuee98ffc95-02d6-4bb7-bc1b-617b64aae288.png"
              />
            </div>
            <span class="text_151">科学园/海日湾/宽敞明亮....</span>
            <div class="group_54 flex-row">
              <div class="image-text_59 flex-row justify-between">
                <img
                  class="image_22"
                  referrerpolicy="no-referrer"
                  src="./assets/img/ps5mml6qpogjjlgxpck3osfg850la4k936287943-8b0d-495c-b185-4ffd58f43970.png"
                />
                <span class="text-group_17">大围/沙田</span>
              </div>
              <div class="group_18 flex-col"></div>
              <span class="text_152">单间</span>
              <div class="group_19 flex-col"></div>
              <span class="text_153">130平方呎</span>
            </div>
            <div class="group_55 flex-row justify-between">
              <div class="text-wrapper_35 flex-col"><span class="text_154">男女皆可</span></div>
              <div class="text-wrapper_36 flex-col"><span class="text_155">有电梯</span></div>
              <div class="text-wrapper_37 flex-col"><span class="text_156">可煮饭</span></div>
            </div>
            <div class="text-wrapper_38">
              <span class="text_157">8800</span>
              <span class="text_158">HKS/月</span>
            </div>
          </div>
        </div>
        <div class="box_52 flex-row justify-between">
          <div class="box_22 flex-col">
            <div class="image-wrapper_16 flex-col">
              <img
                class="image_23"
                referrerpolicy="no-referrer"
                src="./assets/img/psntti7xc2mx82j7wqp2f8khrqk5passxd97d0e1bd3-d3b4-48e1-a0e4-1fa1700a16b6.png"
              />
            </div>
            <span class="text_159">科学园/海日湾/宽敞明亮....</span>
            <div class="box_53 flex-row">
              <div class="image-text_60 flex-row justify-between">
                <img
                  class="image_24"
                  referrerpolicy="no-referrer"
                  src="./assets/img/pst9uks4izdg9i7uy7sxqrdldskgakg1t12c9a46d-4011-4279-93b0-d8d86ec1f5dc.png"
                />
                <span class="text-group_18">大围/沙田</span>
              </div>
              <div class="block_9 flex-col"></div>
              <span class="text_160">单间</span>
              <div class="block_10 flex-col"></div>
              <span class="text_161">130平方呎</span>
            </div>
            <div class="box_54 flex-row justify-between">
              <div class="text-wrapper_39 flex-col"><span class="text_162">男女皆可</span></div>
              <div class="text-wrapper_40 flex-col"><span class="text_163">有电梯</span></div>
              <div class="text-wrapper_41 flex-col"><span class="text_164">可煮饭</span></div>
            </div>
            <div class="text-wrapper_42">
              <span class="text_165">8800</span>
              <span class="text_166">HKS/月</span>
            </div>
          </div>
          <div class="box_23 flex-col">
            <div class="image-wrapper_17 flex-col">
              <img
                class="image_25"
                referrerpolicy="no-referrer"
                src="./assets/img/ps8o3hmgy5end4ue10tvhkhraxx63ll96e520b4072-a868-450e-90c4-5c90ecb97b23.png"
              />
            </div>
            <span class="text_167">科学园/海日湾/宽敞明亮....</span>
            <div class="block_16 flex-row">
              <div class="image-text_61 flex-row justify-between">
                <img
                  class="image_26"
                  referrerpolicy="no-referrer"
                  src="./assets/img/pspi7lw4j1mwefephgthhs4ojwnw768qych15034b3d-3a0a-4bd1-9ff1-b1092717765a.png"
                />
                <span class="text-group_19">大围/沙田</span>
              </div>
              <div class="group_23 flex-col"></div>
              <span class="text_168">单间</span>
              <div class="group_24 flex-col"></div>
              <span class="text_169">130平方呎</span>
            </div>
            <div class="block_17 flex-row justify-between">
              <div class="text-wrapper_43 flex-col"><span class="text_170">男女皆可</span></div>
              <div class="text-wrapper_44 flex-col"><span class="text_171">有电梯</span></div>
              <div class="text-wrapper_45 flex-col"><span class="text_172">可煮饭</span></div>
            </div>
            <div class="text-wrapper_46">
              <span class="text_173">8800</span>
              <span class="text_174">HKS/月</span>
            </div>
          </div>
          <div class="box_26 flex-col">
            <div class="image-wrapper_18 flex-col">
              <img
                class="image_27"
                referrerpolicy="no-referrer"
                src="./assets/img/psh1mognhz6w50sq1nf5qoa6dsjr4nfciwa9e6dfb0c-377d-47fc-a952-c907fb29cc94.png"
              />
            </div>
            <span class="text_175">科学园/海日湾/宽敞明亮....</span>
            <div class="group_56 flex-row">
              <div class="image-text_62 flex-row justify-between">
                <img
                  class="image_28"
                  referrerpolicy="no-referrer"
                  src="./assets/img/pskx564bmmxzjaywayf68aan9oy5z2nng09cc36ff9e-332b-4974-9245-4367264160b1.png"
                />
                <span class="text-group_20">大围/沙田</span>
              </div>
              <div class="group_25 flex-col"></div>
              <span class="text_176">单间</span>
              <div class="group_26 flex-col"></div>
              <span class="text_177">130平方呎</span>
            </div>
            <div class="group_57 flex-row justify-between">
              <div class="text-wrapper_47 flex-col"><span class="text_178">男女皆可</span></div>
              <div class="text-wrapper_48 flex-col"><span class="text_179">有电梯</span></div>
              <div class="text-wrapper_49 flex-col"><span class="text_180">可煮饭</span></div>
            </div>
            <div class="text-wrapper_50">
              <span class="text_181">8800</span>
              <span class="text_182">HKS/月</span>
            </div>
          </div>
        </div>
        <div class="box_55 flex-row">
          <div class="image-wrapper_19 flex-col">
            <img
              class="thumbnail_20"
              referrerpolicy="no-referrer"
              src="./assets/img/psl9twjwdcyp9mhcz41firbr8sd3has80c264a42f0-463c-4baf-a06c-a26d89817f00.png"
            />
          </div>
          <div class="text-wrapper_51 flex-col"><span class="text_183">1</span></div>
          <div class="text-wrapper_52 flex-col"><span class="text_184">2</span></div>
          <div class="text-wrapper_53 flex-col"><span class="text_185">3</span></div>
          <div class="text-wrapper_54 flex-col"><span class="text_186">4</span></div>
          <div class="text-wrapper_55 flex-col"><span class="text_187">5</span></div>
          <div class="image-wrapper_20 flex-col">
            <img
              class="thumbnail_21"
              referrerpolicy="no-referrer"
              src="./assets/img/psrqg6c4dzf94dzoin582v3aof9xjitijt8b8fdb30-dc01-4560-94a2-024314c70a54.png"
            />
          </div>
          <span class="text_188">共</span>
          <span class="text_189">9</span>
          <span class="text_190">页，</span>
          <span class="text_191">到第</span>
          <div class="group_27 flex-col"></div>
          <span class="text_192">页</span>
          <div class="text-wrapper_56 flex-col"><span class="text_193">确定</span></div>
        </div>
      </div>
      <div class="group_28 flex-col">
        <div class="section_16 flex-row">
          <div class="block_13 flex-row">
            <div class="image-text_63 flex-row justify-between">
              <img
                class="label_3"
                referrerpolicy="no-referrer"
                src="./assets/img/psjstyue4xh5dyhfz416a4tegmged703e25dc1101c4-caaf-4d75-b01d-ec0a127fae1c.png"
              />
              <span class="text-group_21">简体中文</span>
            </div>
            <img
              class="thumbnail_22"
              referrerpolicy="no-referrer"
              src="./assets/img/ps5cvfl1xcpbnv4gip17k337j8u4ttx7jh8b0c2d1b1-ee6a-4548-a3e2-5cdd63e9df5a.png"
            />
          </div>
          <span class="text_194">港专租</span>
          <span class="text_195">合作咨询</span>
          <span class="text_196">其他</span>
          <span class="text_197">订阅邮件咨询</span>
        </div>
        <div class="section_17 flex-row">
          <div class="text-wrapper_89 flex-col justify-between">
            <span class="text_198">关于我们</span>
            <span class="text_199">官方微信</span>
          </div>
          <div class="text-wrapper_90 flex-col justify-between">
            <span class="text_200">成为合作伙伴</span>
            <span class="text_201">房源合作</span>
          </div>
          <div class="text-wrapper_91 flex-col justify-between">
            <span class="text_202">常见问题</span>
            <span class="text_203">加入我们</span>
          </div>
          <div class="box_27 flex-row">
            <span class="text_204">输入邮件地址</span>
            <div class="section_14 flex-col">
              <div class="image-wrapper_21 flex-col">
                <img
                  class="image_29"
                  referrerpolicy="no-referrer"
                  src="./assets/img/psbqf6xawgxya5i786y1uwj30f190etkb6uh68f8664e-2054-448f-9f6e-ddf0c127605d.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="text-wrapper_92 flex-row"><span class="text_205">虚位以待</span></div>
        <div class="text-wrapper_93 flex-row"><span class="text_206">Copy&nbsp;Right©中国香港港专租有限公司</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />