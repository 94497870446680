<template>
    <div>
        <Top_head></Top_head>


        <div class="about">
            <div class="g_cw g_mt">
                <div class="g_title">
                    <h3>关于我们 / </h3>
                    <h4>About us</h4>
                </div>
            </div>
            <div class="about_bj details g_mb">
                <div class="g_cw">
                    <div class="left">
                        <h3 class="l1">关于港专租</h3>
                        <p class="xh"></p>
                        <p class="l2">
                            港专租是香港最大港漂租房公司，主要为来香港留学的学生提供优质的房源，港专租的房源都是房东签约+公寓管理模式，包含家具电器，一站式服务包括：全年维修服务、每周清洁、24小时WiFi等全方位服务，方便来港同学拎包入住。
                        </p>
                    </div>
                    <div class="right">
                        <img src="images/about_ct.jpg">
                    </div>
                </div>
            </div>


            <div class="details_a">
                <div class="g_cw">
                    <div class="left_a">
                        <img class="dt_ct_a" src="images/about_ct_b.jpg">
                    </div>
                    <div class="right_a">
                        <ul class="list">
                            <li>
                                <p class="l1">
                                    为同学打造的绿色公寓，确保同学在法律上有最大的保障的同时也让学生租客可以找到无忧无虑的共居住所，
                                    <br>免于物业维修及管理的烦恼，享受低空气污染，远离污水及空气致敏源的生活。
                                    <br>物业管理监管局
                                    牌照号码：C-851662
                                </p>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="js  g_cw g_mt g_mb">
                <div class="js_xq">
                    <ul class="list">
                        <li>
                            <a href="javascript:void(0)">
                                <div class="tb_bj_a">
                                    <img class="" src="images/ab_dw_b.svg">
                                </div>
                                <div class="tb_bj_b">
                                    <img class="" src="images/ab_dw_a.svg">
                                </div>
                                <h3 class="l1">地理位置优越</h3>
                                <p class="l2">公寓位于香港市中心商圈区域，交通便利出行方便，靠近大学、大型商场、地铁、医院等</p>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <div class="tb_bj_a">
                                    <img class="" src="images/ab_zx_b.svg">
                                </div>
                                <div class="tb_bj_b">
                                    <img class="" src="images/ab_zx_a.svg">
                                </div>
                                <h3 class="l1">室内精致装修</h3>
                                <p class="l2">公寓位于香港市中心商圈区域，交通便利出行方便，靠近大学、大型商场、地铁、医院等</p>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <div class="tb_bj_a">
                                    <img class="" src="images/ab_aq_b.svg">
                                </div>
                                <div class="tb_bj_b">
                                    <img class="" src="images/ab_aq_a.svg">
                                </div>
                                <h3 class="l1">高级安全系数</h3>
                                <p class="l2">公寓位于香港市中心商圈区域，交通便利出行方便，靠近大学、大型商场、地铁、医院等</p>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">
                                <div class="tb_bj_a">
                                    <img class="" src="images/ab_hj_b.svg">
                                </div>
                                <div class="tb_bj_b">
                                    <img class="" src="images/ab_hj_a.svg">
                                </div>
                                <h3 class="l1">住宿环境良好</h3>
                                <p class="l2">公寓位于香港市中心商圈区域，交通便利出行方便，靠近大学、大型商场、地铁、医院等</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>


        <Bottom></Bottom>
    </div>
</template>
<script>

import Top_head from '@/components/Top_head.vue';
import Bottom from "@/components/Bottom.vue";


export default {
    components: { Top_head, Bottom }
}
</script>
<style  lang="css" src="../../../public/css/index.css" />
<style  lang="css" src="../../../public/css/reset.css" />
