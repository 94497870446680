import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
axios.defaults.baseURL = 'https://api.gzzrent.vip/api/'
const service = axios.create({
  baseURL: process.env.VUE_APP_API, // 请求的接口
  timeout: 100000 // 请求超时时间
})
Vue.prototype.$http = service
Vue.prototype.$axios = axios
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
new Vue({
  router,
  render: h => h(App),
  data: function () {
    return {
      serverUrl: "https://api.gzzrent.vip/upload_file/",
      apiUrl: 'https://api.gzzrent.vip/api/admin/',
      section: ["中西区", "东区", "南区", "湾仔区", "九龙城区", "观塘区", "深水埗区", "黄大仙区", "油尖旺区", "离岛区", "葵青区", "北区", "西贡区", "沙田区", "大埔区", "荃湾区", "屯门区", "元朗区", "将军澳", "坑口", "大围", "火炭", "红磡", "马鞍山", "何文田"],
    }
  },
}).$mount('#app')
