<template>
  <div>
    <div class="g_header clearfix">
      <div class="bar">
        <a href="#" class="menu"><img src="images/cd.png" /></a>
        <p class="logo"><a href="index.html"><img src="img/black_logo.png"></a></p>
      </div>
      <div class="main">
        <div class="g_cw_a">
          <a href="index.html" class="logo"><img src="img/black_logo.png"></a>
          <ul class="nav">
            <li v-for="obj in menu_data" :key="obj.index">
              <a href="javascript:void(0)" @click="click_menu(obj.link)" :class="obj.class">{{
                obj.title
              }}</a>
            </li>

          </ul>
        </div>
      </div>
    </div>
    <div>
      <div :style="'background: url(' + banner + ') no-repeat center center; background-size:cover; height:320px;'">
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      index: "home",
      menu_data: [
        {
          "column": "home",
          "title": "首页",
          "link": "/",
          "class": ""
        },
        {
          "column": "all",
          "title": "全部房源",
          "link": "/indexList",
          "class": ""
        },

        {
          "column": "service",
          "title": "服务",
          "link": "/service",
          "class": ""
        },
        {
          "column": "event",
          "title": "最新活动",
          "link": "/event",
          "class": ""
        },
        {
          "column": "news",
          "title": "最新消息",
          "link": "/news",
          "class": ""
        },
        {
          "column": "work",
          "title": "工作配对",
          "link": "/newsDetail?id=12",
          "class": ""
        },
      ],
      banner: "images/ban1.jpg"
    };
  },
  watch: {
    $route(to, from) {
      if (from !== to) {
        this.switch_menu();
      }
    },
  },
  created: async function () {
    this.switch_menu();
  },
  methods: {
    switch_menu() {
      let page = this.$route.path;
      var that = this

      let menu_data = this.menu_data;
      if (page == '/indexList') {
        that.banner = "images/ban2.jpg"

      }
      if (page == '/service') {
        that.banner = "images/ban3.jpg"
      }
      if (page == '/news') {
        that.banner = "images/ban4.jpg"
      }
      if (page == '/aboutUs') {
        that.banner = "images/ban5.jpg"
      }
      if (page == '/detail') {


        that.banner = "images/ban2.jpg"

      }
      if (page == '/newsDetail?id=12') {


        that.banner = "images/ban4.jpg"

      }
      if (page == '/eventDetail') {


        that.banner = "images/ban1.jpg"

      }
      menu_data.forEach(function (item) {


        if (item.link == page) {
          item.class = "on";

        }
        else {

          item.class = "";
        }
      });
      this.menu_data = menu_data;
    },
    click_menu(topage) {
      this.$router.push(topage);
    },
    // get_data() {
    //   this.menu_data = [];
    //   api.load_menu().then((res) => {
    //     this.menu_data = res.data;
    //   });
    // },
  },
};
</script>
