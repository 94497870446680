import Vue from "vue";
import VueRouter from "vue-router";
import lanhu_gerenzhongxin from '../views/lanhu_gerenzhongxin/index.vue'
import lanhu_gerenzhongxinwodeguanzhu from '../views/lanhu_gerenzhongxinwodeguanzhu/index.vue'
import lanhu_zuixinhuodong from '../views/lanhu_zuixinhuodong/index.vue'
import lanhu_fuwu from '../views/lanhu_fuwu/index.vue'

import detail from "../views/detail/index"
import lanhu_pcshouye from '../views/lanhu_pcshouye/index.vue'
import lanhu_fangyuanliebiaoshouqi from '../views/lanhu_fangyuanliebiaoshouqi/index.vue'
import lanhu_fangyuanliebiaozhankai from '../views/lanhu_fangyuanliebiaozhankai/index.vue'
import denglu from "../views/lanhu_dengluyouxiangweizhuce/index.vue"
import index_list from "../views/html/index"
import event from "../views/html/event"
import event_detail from "../views/html/event_detail"
import contact_us from "../views/html/contact_us"
import news from "../views/html/news"
import newsDetail from "../views/html/news_detail"
import about_us from "../views/html/about_us"
const originalPush = VueRouter.prototype.replace

VueRouter.prototype.replace = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "homepage",
  //   meta: {
  //     title: '主页',
  //     keepAlive: true
  //   },
  //   component: homepage,
  // },
  // {
  //   path: "/article",
  //   name: "article",
  //   meta: {
  //     title: '文章',
  //     keepAlive: true
  //   },
  //   component: article,
  // },
  // {
  //   path: "/lists",
  //   name: "lists",
  //   meta: {
  //     title: '列表',
  //     keepAlive: true
  //   },
  //   component: lists,
  // },


  {
    path: '/',
    name: 'lanhu_pcshouye',
    component: lanhu_pcshouye,
    meta: {
      title: '港专租',

    }
  },
  {
    path: '/denglu',
    name: 'denglu',
    component: denglu,
    meta: {
      title: '港专租--登录',

    }
  },
  {
    path: '/news',
    name: 'news',
    component: news,
    meta: {
      title: '港专租--最新消息',

    }
  },
  {
    path: '/aboutUs',
    name: 'about us',
    component: about_us,
    meta: {
      title: '港专租--关于我们',

    }
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: newsDetail,
    meta: {
      title: '港专租--消息详情',

    }
  },

  {
    path: '/contactUs',
    name: 'contact_us',
    component: contact_us,
    meta: {
      title: '港专租--联系我们',

    }
  },
  {
    path: '/indexList',
    name: 'list',
    component: index_list,
    meta: {
      title: '港专租--全部房源',

    }
  },
  {
    path: '/event',
    name: 'list',
    component: event,
    meta: {
      title: '港专租--活动中心',

    }
  },
  {
    path: '/eventDetail',
    name: 'detail',
    component: event_detail,
    meta: {
      title: '港专租--活动详情',

    }
  },


  {
    path: '/lanhu_gerenzhongxin',
    name: 'lanhu_gerenzhongxin',
    component: lanhu_gerenzhongxin
  },
  {
    path: '/lanhu_gerenzhongxinwodeguanzhu',
    name: 'lanhu_gerenzhongxinwodeguanzhu',
    component: lanhu_gerenzhongxinwodeguanzhu
  },
  {
    path: '/lanhu_zuixinhuodong',
    name: 'lanhu_zuixinhuodong',
    component: lanhu_zuixinhuodong
  },
  {
    path: '/service',
    name: 'lanhu_fuwu',
    component: lanhu_fuwu,
    meta: {
      title: '港专租--我们的服务',

    }
  },
  {
    path: '/detail',
    name: '详情',
    component: detail,
    meta: {
      title: '港专租--全部房源',

    }
  },

  {
    path: '/lanhu_pcshouye',
    name: 'lanhu_pcshouye',
    component: lanhu_pcshouye
  },
  {
    path: '/houserList',
    name: 'lanhu_fangyuanliebiaoshouqi',
    component: lanhu_fangyuanliebiaoshouqi
  },
  {
    path: '/lanhu_fangyuanliebiaozhankai',
    name: 'lanhu_fangyuanliebiaozhankai',
    component: lanhu_fangyuanliebiaozhankai
  }

]


const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})



export default router
