<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="box_1 flex-col">
        <div class="group_1 flex-col justify-end">
          <div class="box_2 flex-row">
            <div class="group_2 flex-col"></div>
            <span class="text_1">迷一样的租客</span>
            <div class="group_3 flex-col"></div>
            <span class="text_2">退出登录</span>
            <div class="group_4 flex-col"></div>
            <span class="text_3">返回官网</span>
          </div>
          <img
            class="image_1"
            referrerpolicy="no-referrer"
            src="./assets/img/ps2dss723g28ms68046mt4tdgta93z30fcaad856d1-487c-47c4-90fc-6e766f9ab019.png"
          />
        </div>
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/psaunqr040kowbckh8b9ux8b5glotnsqjm6a1d3cad-a388-4923-b5cf-4fbe07acfb69.png"
        />
      </div>
      <div class="box_3 flex-col">
        <div class="box_4 flex-row justify-between">
          <div class="box_5 flex-col">
            <div class="box_6 flex-row justify-between">
              <div class="image-wrapper_1 flex-col">
                <img
                  class="thumbnail_1"
                  referrerpolicy="no-referrer"
                  src="./assets/img/pspoynqrzxwygtbz79m6gpwd7kt3t25gq614bb56b9-1000-4151-99ea-44868823753e.png"
                />
              </div>
              <span class="text_4">个人中心</span>
            </div>
            <span class="text_5">帐号信息</span>
            <span class="text_6">资料编辑</span>
            <div class="box_7 flex-row justify-between">
              <div class="image-wrapper_2 flex-col">
                <img
                  class="thumbnail_2"
                  referrerpolicy="no-referrer"
                  src="./assets/img/pshg0l6ycv8ztsd39iif8nu4t3lcl91lr4b47ca88-df7c-4fd4-a849-b4d09fb3dada.png"
                />
              </div>
              <span class="text_7">房源订单</span>
            </div>
            <span class="text_8">房源租赁</span>
            <span class="text_9">费用收据</span>
            <div class="box_8 flex-row justify-between">
              <div class="image-wrapper_3 flex-col">
                <img
                  class="thumbnail_3"
                  referrerpolicy="no-referrer"
                  src="./assets/img/ps9o7rbjxknjiyzmxreuronrgexsy8tk6ic5c39d32-341e-4350-affc-26c06ebbc460.png"
                />
              </div>
              <span class="text_10">其他功能</span>
            </div>
            <span class="text_11">消息中心</span>
            <span class="text_12">活动中心</span>
            <span class="text_13">我的积分</span>
            <span class="text_14">浏览足迹</span>
            <div class="box_9 flex-col">
              <span class="text_15">我的关注</span>
              <div class="box_10 flex-col"></div>
            </div>
          </div>
          <div class="box_11 flex-col">
            <div class="block_2 flex-row">
              <span class="text_16">我的关注</span>
              <span class="text_17">全选</span>
              <div class="box_12 flex-col"></div>
              <span class="text_18">删除</span>
            </div>
            <div class="block_3 flex-row justify-between">
              <div class="section_1 flex-col">
                <div class="image-wrapper_4 flex-col">
                  <img
                    class="label_1"
                    referrerpolicy="no-referrer"
                    src="./assets/img/psbsmwf4e2p2bmwf02l43mwidrh330xsd343ace0fb-e571-4b88-90ab-2da6d16116bd.png"
                  />
                </div>
                <span class="text_19">科学园/海日湾/宽敞明亮....</span>
                <div class="block_4 flex-row">
                  <div class="image-text_1 flex-row justify-between">
                    <img
                      class="thumbnail_4"
                      referrerpolicy="no-referrer"
                      src="./assets/img/pskcpm0qrzus94u3qhtc3q43o8x5xvsv1anfc6be6af-0b1a-4ace-aca7-f4d1e9f173b6.png"
                    />
                    <span class="text-group_1">大围/沙田</span>
                  </div>
                  <div class="group_5 flex-col"></div>
                  <span class="text_20">单间</span>
                  <div class="group_6 flex-col"></div>
                  <span class="text_21">130平方呎</span>
                </div>
                <div class="block_5 flex-row">
                  <div class="text-wrapper_1 flex-col"><span class="text_22">男女皆可</span></div>
                  <div class="text-wrapper_2 flex-col"><span class="text_23">有电梯</span></div>
                  <div class="text-wrapper_3 flex-col"><span class="text_24">可煮饭</span></div>
                </div>
                <div class="block_6 flex-row justify-between">
                  <div class="text-wrapper_4">
                    <span class="text_25">8800</span>
                    <span class="text_26">HKS/月</span>
                  </div>
                  <img
                    class="image_3"
                    referrerpolicy="no-referrer"
                    src="./assets/img/ps54bu2zv3gtubst3bmi9e36b7fcz3pny4p8f3a2b8c-3476-4069-9830-2abc1abe97cd.png"
                  />
                </div>
              </div>
              <div class="section_2 flex-col">
                <div class="image-wrapper_5 flex-col">
                  <img
                    class="label_2"
                    referrerpolicy="no-referrer"
                    src="./assets/img/ps5rbjm7f9vz14u52849m89he05qsihac879f0af71-671f-4503-892b-35f6ecd79c51.png"
                  />
                </div>
                <span class="text_27">科学园/海日湾/宽敞明亮....</span>
                <div class="block_7 flex-row">
                  <div class="image-text_2 flex-row justify-between">
                    <img
                      class="thumbnail_5"
                      referrerpolicy="no-referrer"
                      src="./assets/img/pspehi18wle2i269uszyh29a3gthqb2vgef2384a349-844f-4ceb-b990-7f08d7ff149d.png"
                    />
                    <span class="text-group_2">大围/沙田</span>
                  </div>
                  <div class="section_3 flex-col"></div>
                  <span class="text_28">单间</span>
                  <div class="section_4 flex-col"></div>
                  <span class="text_29">130平方呎</span>
                </div>
                <div class="block_8 flex-row">
                  <div class="text-wrapper_5 flex-col"><span class="text_30">男女皆可</span></div>
                  <div class="text-wrapper_6 flex-col"><span class="text_31">有电梯</span></div>
                  <div class="text-wrapper_7 flex-col"><span class="text_32">可煮饭</span></div>
                </div>
                <div class="block_9 flex-row justify-between">
                  <div class="text-wrapper_8">
                    <span class="text_33">8800</span>
                    <span class="text_34">HKS/月</span>
                  </div>
                  <img
                    class="image_4"
                    referrerpolicy="no-referrer"
                    src="./assets/img/pshxc0li2fnjty826dq0e8ssr7vxchp0xbcfd774b-fe11-470d-9204-59d95387db66.png"
                  />
                </div>
              </div>
              <div class="section_5 flex-col">
                <div class="image-wrapper_6 flex-col">
                  <img
                    class="label_3"
                    referrerpolicy="no-referrer"
                    src="./assets/img/pshw6ya0psomnmkgam4eiz0anmilbl3hfhj5b22bb7b-7a34-4a45-bc2b-8e1bd9814243.png"
                  />
                </div>
                <span class="text_35">科学园/海日湾/宽敞明亮....</span>
                <div class="group_7 flex-row">
                  <div class="image-text_3 flex-row justify-between">
                    <img
                      class="thumbnail_6"
                      referrerpolicy="no-referrer"
                      src="./assets/img/psucy1qr1vrnkgxxq1cgiksrl2tkvb2asfd1c9a3e-78d8-468d-9b1b-6720e0a49fe7.png"
                    />
                    <span class="text-group_3">大围/沙田</span>
                  </div>
                  <div class="group_8 flex-col"></div>
                  <span class="text_36">单间</span>
                  <div class="group_9 flex-col"></div>
                  <span class="text_37">130平方呎</span>
                </div>
                <div class="group_10 flex-row">
                  <div class="text-wrapper_9 flex-col"><span class="text_38">男女皆可</span></div>
                  <div class="text-wrapper_10 flex-col"><span class="text_39">有电梯</span></div>
                  <div class="text-wrapper_11 flex-col"><span class="text_40">可煮饭</span></div>
                </div>
                <div class="group_11 flex-row justify-between">
                  <div class="text-wrapper_12">
                    <span class="text_41">8800</span>
                    <span class="text_42">HKS/月</span>
                  </div>
                  <img
                    class="image_5"
                    referrerpolicy="no-referrer"
                    src="./assets/img/psqizrt3eybc4oeimc73jqpc3xb7o27dcw50278e7c-5036-46ee-9576-8c30e165e8a7.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box_13 flex-col">
        <div class="block_10 flex-row">
          <div class="section_6 flex-row">
            <div class="image-text_4 flex-row justify-between">
              <img
                class="label_4"
                referrerpolicy="no-referrer"
                src="./assets/img/psj5p6qmf9ozx0ovsqani26ebshv94wog93f4f8a7-61a0-4404-b870-3406d3807ad6.png"
              />
              <span class="text-group_4">简体中文</span>
            </div>
            <img
              class="thumbnail_7"
              referrerpolicy="no-referrer"
              src="./assets/img/psfnxgjlsqmhaf6dat61jikd5dlkqyok8ue83518407-227c-421d-8666-239301f034d8.png"
            />
          </div>
          <span class="text_43">港专租</span>
          <span class="text_44">合作咨询</span>
          <span class="text_45">其他</span>
          <span class="text_46">订阅邮件咨询</span>
        </div>
        <div class="block_11 flex-row">
          <div class="text-wrapper_13 flex-col justify-between">
            <span class="text_47">关于我们</span>
            <span class="text_48">官方微信</span>
          </div>
          <div class="text-wrapper_14 flex-col justify-between">
            <span class="text_49">成为合作伙伴</span>
            <span class="text_50">房源合作</span>
          </div>
          <div class="text-wrapper_15 flex-col justify-between">
            <span class="text_51">常见问题</span>
            <span class="text_52">加入我们</span>
          </div>
          <div class="group_12 flex-row">
            <span class="text_53">输入邮件地址</span>
            <div class="section_7 flex-col">
              <div class="image-wrapper_7 flex-col">
                <img
                  class="image_6"
                  referrerpolicy="no-referrer"
                  src="./assets/img/ps76nq75nqwnmcm0panagwne26tw0do1q5r6e649392-7cd4-4bfb-8b63-89e82a4c430e.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="text-wrapper_16 flex-row"><span class="text_54">虚位以待</span></div>
        <div class="text-wrapper_17 flex-row"><span class="text_55">Copy&nbsp;Right©中国香港港专租有限公司</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />